import React from "react";
import { Layout } from "../layout";
import { Breadcrumbs } from "../layout/Breadcrumbs";
import { PortCentralServer } from "../lib/domain/Ports/PortCentralServer";
import LoaderComponent from "../components/LoaderComponent";

export type HomePageProps = {};

type HomePageState = {
  totalUsers: number;
};

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  state: HomePageState = {
    totalUsers: 0
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    const totalUsers = await PortCentralServer.Repo.client.filterPlatformUserTotal();

    console.log("totalUsers", totalUsers);

    await this.setState((prevState) => {
      return { totalUsers: totalUsers };
    });
  };

  componentDidUpdate = async (prevProps: HomePageProps, prevState: HomePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboard" />

              <div className="row">
                <div className="col-md-4">
                  <div className="mini-stats-wid card">
                    <div className="card-body">
                      {!this.state.totalUsers || this.state.totalUsers == 0 ? (
                        <LoaderComponent />
                      ) : (
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="fw-medium">Total Users</p>
                            <h4 className="mb-0">{this.state.totalUsers.toLocaleString()}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className="bx bx-user font-size-24" />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}
