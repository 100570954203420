import React from "react";
import { Col, Row, Table } from "reactstrap";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { PlatformUserRegistrationsByDayDto } from "../../lib/drivers/dto/platform-data/PlatformUserRegistrationsByDayDto";
import _ from "lodash";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ChartData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    fill: boolean;
    backgroundColor: string;
    borderColor: string;
  }>;
}

export type PlatformUserRegistrationsByDatePageProps = {};

type PlatformUserRegistrationsByDatePageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: PlatformUserRegistrationsByDayDto[];
};

class PlatformUserRegistrationsByDatePage extends React.Component<PlatformUserRegistrationsByDatePageProps, PlatformUserRegistrationsByDatePageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PlatformUserRegistrationsByDatePageState = {
    isLoading: false,
    startDate: new Date(),
    columns: [],
    list: []
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    const dataFilterLoggingResponseList = await this.loadData();

    await this.setState((prevState) => {
      return { list: dataFilterLoggingResponseList };
    });
  };

  componentDidUpdate = async (prevProps: PlatformUserRegistrationsByDatePageProps, prevState: PlatformUserRegistrationsByDatePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleSelect(e: React.ChangeEvent<HTMLSelectElement>): void {
    // let search = e.target.value;
    // if (search === "All") {
    //   setOrder(orders)
    // } else {
    //   handleSearchData({ setState: setOrder, data: orders, item: search })
    // }
  }
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };

  setStartDate = async (date: Date) => {
    await this.setState((prevState) => {
      return {
        startDate: date
      };
    });
  };
  // #endregion

  // #region Functions
  private async loadData(): Promise<PlatformUserRegistrationsByDayDto[]> {
    const result = await PortCentralServer.Repo.client.reportingPlatformUserRegistrationByDayList();

    return result;
  }

  getChartData = (): ChartData => {
    // const sortedList: PlatformUserRegistrationsByDayDto[] = _.orderBy(this.state.list, ["year", "month", "day"], ["asc", "asc", "asc"]);

    const today = new Date();
    const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

    const sixMonthsAgoTimestamp = sixMonthsAgo.getTime();
    const filteredList = this.state.list.filter((item) => {
      const itemDate = new Date(item.year, item.month - 1, item.day); // Month is 0-indexed in JS Date
      return itemDate.getTime() >= sixMonthsAgoTimestamp;
    });

    const sortedList: PlatformUserRegistrationsByDayDto[] = _.orderBy(filteredList, ["year", "month", "day"], ["asc", "asc", "asc"]);

    const labels = sortedList.map((item) => `${item.year}-${item.month}-${item.day}`);
    const data = sortedList.map((item) => item.user_count);

    return {
      labels,
      datasets: [
        {
          label: "User Registrations",
          data: data,
          fill: false,
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgba(75, 192, 192, 0.2)"
        }
      ]
    };
  };
  // #endregion

  render() {
    const chartOptions = {
      scales: {
        x: {
          type: "category" as const // specifying 'category' as a literal type
          // additional configuration for the x-axis if needed
        },
        y: {
          // configuration for the y-axis
        }
      }
      // other chart options...
    };

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Platform" breadcrumbItem="Registrations by day" />
              <Row>
                <Col xl={12}>
                  <Line data={this.getChartData()} options={chartOptions} />
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <div className="table-responsive">
                    <Table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th>Year</th>
                          <th>Month</th>
                          <th>Day</th>
                          <th>Registrations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.list || []).map((item: PlatformUserRegistrationsByDayDto, key: number) => (
                          <tr key={key}>
                            <td>{item.year}</td>
                            <td>{item.month}</td>
                            <td>{item.day}</td>
                            <td>{item.user_count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(PlatformUserRegistrationsByDatePage);
