import React from "react";
import { Col, Row } from "reactstrap";
import { BaseModal } from "../../components/modals/BaseModal";
import { BonusClaimDto } from "../../lib/drivers/dto/bonus/BonusClaimDto";
import { BonusClaimStatus } from "../../lib/drivers/dto/enums/BonusClaimStatus";

export type ModalSetBonusClaimStatusArgs = {
  claim: BonusClaimDto;
};

export type ModalSetBonusClaimStatusProps = {
  claim: BonusClaimDto;
  newStatus: BonusClaimStatus;
  onCloseClick(): void;
  onSubmitClick(args: ModalSetBonusClaimStatusArgs): void;
};

type ModalSetBonusClaimStatusState = {
  here: boolean;
};

export class ModalSetBonusClaimStatus extends React.Component<ModalSetBonusClaimStatusProps, ModalSetBonusClaimStatusState> {
  state: ModalSetBonusClaimStatusState = {
    here: true
  };

  refComment: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ModalSetBonusClaimStatusProps) {
    super(props);

    this.refComment = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalSetBonusClaimStatusProps, prevState: ModalSetBonusClaimStatusState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const comment: string = this.refComment.current ? this.refComment.current.value : "";

    const args: ModalSetBonusClaimStatusArgs = {
      claim: { ...this.props.claim, status: this.props.newStatus, comment: comment }
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <BaseModal
          size="lg"
          title="Set Bonus Claim Status"
          onCloseClick={this.onCloseClick}
          onSubmitClick={this.onSubmitClick}
          content={
            <>
              <Row className="mb-4">
                <label className="col-sm-3 col-form-label">Comment</label>
                <Col sm={9}>
                  <textarea ref={this.refComment} className="form-control" placeholder="Comment" rows={4} maxLength={1000} />
                </Col>
              </Row>
            </>
          }
        />
      </React.Fragment>
    );
  }
}
