import React, { Component } from "react";
import { BonusClaimStatus } from "../../lib/drivers/dto/enums/BonusClaimStatus";
import { PlatformBonusType } from "../../lib/drivers/dto/enums/PlatformBonusType";
import { BonusPaymentStatus } from "../../lib/drivers/dto/enums/BonusPaymentStatus";
import { ExchangePartnerIndexStatus } from "../../lib/drivers/dto/enums/ExchangePartnerIndexStatus";
import { ExchangePartnerStatus } from "../../lib/drivers/dto/enums/ExchangePartnerStatus";

interface IProps {
  enumType: "BonusClaimStatus" | "PlatformBonusType" | "BonusPaymentStatus" | "ExchangePartnerIndexStatus" | "ExchangePartnerStatus";
  value: BonusClaimStatus | PlatformBonusType | BonusPaymentStatus | ExchangePartnerIndexStatus | ExchangePartnerStatus;
}

export class EnumBadgeControl extends Component<IProps> {
  getBadgeForBonusClaimStatus(value: BonusClaimStatus) {
    let color = "";
    switch (value) {
      case BonusClaimStatus.NOT_SET:
        color = "primary";
        break;
      case BonusClaimStatus.APPROVED:
        color = "warning";
        break;
      case BonusClaimStatus.REJECTED:
        color = "danger";
        break;
      case BonusClaimStatus.PROCESSED:
        color = "info";
        break;
      default:
        color = "primary";
    }
    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  getBadgeForPlatformBonusType(value: PlatformBonusType) {
    let color = "";
    switch (value) {
      case PlatformBonusType.NOT_SET:
        color = "secondary";
        break;
      case PlatformBonusType.BUDDY:
        color = "success";
        break;
      case PlatformBonusType.PARTNERS:
        color = "danger";
        break;
      case PlatformBonusType.BOOST:
        color = "info";
        break;
      default:
        color = "light";
    }
    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  getBadgeForBonusPaymentStatus(value: BonusPaymentStatus) {
    let color = "";
    switch (value) {
      case BonusPaymentStatus.NOT_SET:
        color = "primary";
        break;
      case BonusPaymentStatus.APPROVED:
        color = "warning";
        break;
      case BonusPaymentStatus.REJECTED:
        color = "danger";
        break;
      case BonusPaymentStatus.PROCESSED:
        color = "info";
        break;
      default:
        color = "primary";
    }
    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  getBadgeForExchangePartnerStatus(value: ExchangePartnerStatus) {
    let color = "";
    switch (value) {
      case ExchangePartnerStatus.NOT_SET:
        color = "primary";
        break;
      case ExchangePartnerStatus.ACTIVE:
        color = "success";
        break;
      default:
        color = "primary";
    }
    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  getBadgeForExchangePartnerIndexStatus(value: ExchangePartnerIndexStatus) {
    let color = "";
    switch (value) {
      case ExchangePartnerIndexStatus.NOT_SET:
        color = "primary";
        break;
      case ExchangePartnerIndexStatus.PAID:
        color = "warning";
        break;
      case ExchangePartnerIndexStatus.BLOCKED:
        color = "danger";
        break;
      case ExchangePartnerIndexStatus.PROCESSING:
        color = "info";
        break;
      case ExchangePartnerIndexStatus.PROCESSED:
        color = "info";
        break;
      default:
        color = "primary";
    }
    return <span className={`badge bg-${color}`}>{value}</span>;
  }

  render() {
    const { value, enumType } = this.props;
    return (
      <div>
        {enumType === "BonusClaimStatus" && this.getBadgeForBonusClaimStatus(value as BonusClaimStatus)}
        {enumType === "PlatformBonusType" && this.getBadgeForPlatformBonusType(value as PlatformBonusType)}
        {enumType === "BonusPaymentStatus" && this.getBadgeForBonusPaymentStatus(value as BonusPaymentStatus)}
        {enumType === "ExchangePartnerIndexStatus" && this.getBadgeForExchangePartnerIndexStatus(value as ExchangePartnerIndexStatus)}
        {enumType === "ExchangePartnerStatus" && this.getBadgeForExchangePartnerStatus(value as ExchangePartnerStatus)}
      </div>
    );
  }
}
