import React from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { Card, CardBody, Col, Form, Row } from "reactstrap";

export type UserInvitationPageProps = {};

type UserInvitationPageState = {
  here: boolean;
};

class UserInvitationPage extends React.Component<UserInvitationPageProps, UserInvitationPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: UserInvitationPageState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: UserInvitationPageProps, prevState: UserInvitationPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="User" breadcrumbItem="Invitations" />
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={(e) => this.handleSubmit(e)}></Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(UserInvitationPage);
