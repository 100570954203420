import moment from "moment";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { TournamentDetailsComponent } from "../../components/TournamentDetailsComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { TournamentDto } from "../../lib/drivers/dto/tournament/TournamentDto";
import { TournamentPlatformUserOrderReferenceDto } from "../../lib/drivers/dto/tournament/TournamentPlatformUserOrderReferenceDto";
import withRouterHook from "../../withRouterHook";

// flatpickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { UidInfoButtons } from "../../components/controls/UidInfoButtons";

type TournamentOrderListPageParams = ReturnType<typeof useParams> & {
  tournamentId: string;
};

type TournamentOrderListPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: TournamentOrderListPageParams;
};

type Filter = {
  originUid?: string;
  startDate?: Date;
  endDate?: Date;
  page: number;
  pageSize: number;
  total: number;
};

type TournamentOrderListPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];

  entity: TournamentDto;
  list: TournamentPlatformUserOrderReferenceDto[];

  // filter
  filter: Filter;
};

class TournamentOrderListPage extends React.Component<TournamentOrderListPageProps, TournamentOrderListPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: TournamentOrderListPageState = {
    isLoading: true,
    startDate: new Date(),
    columns: [],

    entity: null,
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    if (this.props.params.tournamentId) {
      const entity: TournamentDto = await PortCentralServer.Repo.client.getTournament(this.props.params.tournamentId);

      if (entity) {
        await this.setState((prevState) => {
          return {
            entity: entity
          };
        });

        const queryParams = new URLSearchParams(this.props.location.search);

        const filter: Filter = {
          originUid: queryParams.get("originUid") || "",
          startDate: queryParams.get("startDate") ? moment(queryParams.get("startDate")).toDate() : null,
          endDate: queryParams.get("endDate") ? moment(queryParams.get("endDate")).toDate() : null,

          page: parseInt(queryParams.get("page") || "1", 10),
          pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
          total: 0
        };

        const offset = (filter.page - 1) * filter.pageSize;

        const response = await PortCentralServer.Repo.client.filterTournamentOrders({
          tournamentId: this.props.params.tournamentId,
          originUid: filter.originUid,
          startDate: filter.startDate,
          endDate: filter.endDate,
          limit: filter.pageSize,
          offset: offset
        });

        if (response) {
          filter.total = response.total;

          await this.setState((prevState) => {
            return {
              filter: filter,
              list: response.data,
              isLoading: false
            };
          });
        } else {
          await this.setState((prevState) => {
            return {
              isLoading: false
            };
          });
        }
      }
    }
  };

  componentDidUpdate = async (prevProps: TournamentOrderListPageProps, prevState: TournamentOrderListPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Functions
  // private async loadData(): Promise<LogResponseDto> {
  //   const { currentPage, pageSize } = this.state;
  //   const offset = (currentPage - 1) * pageSize;

  //   const result = await PortCentralServer.Repo.client.filterLogList({
  //     limit: pageSize,
  //     offset: offset
  //   });

  //   return result;
  // }

  badgeForMock(mock: boolean) {
    let color = mock ? "primary" : "";
    let text = mock ? "Mock" : "";

    return <span className={`badge bg-${color}`}>{text}</span>;
  }
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Tournament" breadcrumbItem="Orders" />

              {this.state.entity && (
                <Row>
                  <Col xl={12}>
                    <TournamentDetailsComponent entity={this.state.entity} />
                  </Col>
                </Row>
              )}

              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent setLoading={this.setLoading} />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <h5 className="fw-semibold">Orders ({this.state.list.length})</h5>
                          <Form method="get">
                            <Row className="mb-4">
                              <div className="col-xl col-sm-6">
                                <FormGroup className="mb-0">
                                  <Label>Start Date</Label>
                                  <FlatPickr
                                    className="form-control"
                                    name="startDate"
                                    placeholder="Select time"
                                    value={this.state.filter.startDate}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      locale: {
                                        firstDayOfWeek: 1
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-xl col-sm-6">
                                <FormGroup className="mb-0">
                                  <Label>End Date</Label>
                                  <FlatPickr
                                    className="form-control"
                                    name="endDate"
                                    placeholder="Select time"
                                    value={this.state.filter.endDate}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      locale: {
                                        firstDayOfWeek: 1
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">originUid</Label>
                                  <Input className="form-control" name="originUid" defaultValue={this.state.filter.originUid} />
                                </FormGroup>
                              </Col>

                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>ctime</th>
                                      <th>platformFutureOrderId</th>
                                      <th>contractName</th>
                                      <th>uid</th>
                                      <th>originUid</th>
                                      <th>realVolume</th>
                                      <th>pnl</th>
                                      <th>roi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: TournamentPlatformUserOrderReferenceDto, key: number) => (
                                      <tr key={key}>
                                        <td className="nowrap"></td>
                                        <th>{moment(item.ctime).format("YYYY-MM-DD HH:mm:ss")}</th>
                                        <th>{`${item.platformFutureOrderId}`}</th>
                                        <th>{`${item.contractName}`}</th>
                                        <th>
                                          <UidInfoButtons uid={item.uid.toString()} />
                                        </th>
                                        <th>{`${item.originUid}`}</th>
                                        <th>{`${item.realVolume}`}</th>
                                        <th>{`${item.pnl}`}</th>
                                        <th>{`${item.roi}`}</th>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(TournamentOrderListPage);
