import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

export type SidebarContentProps = {};

type SidebarContentState = {};

export class SidebarContent extends React.Component<SidebarContentProps, SidebarContentState> {
  ref: React.RefObject<SimpleBar>;

  constructor(props: SidebarContentProps) {
    super(props);

    this.ref = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/">
                  <i className="bx bx-file"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Common</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/common/platform-balances">Platform Balances</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Platform</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/platform/users">Users</Link>
                  </li>
                  <li>
                    <Link to="/platform/users/registrations-by-day">User Registrations by day</Link>
                  </li>
                  <li>
                    <Link to="/platform/users/future-balance">Future Balances</Link>
                  </li>
                  <li>
                    <Link to="/platform/users/spot-balance">Spot Balances</Link>
                  </li>
                  <li>
                    <Link to="/platform/users/invitations">User Invitations</Link>
                  </li>
                  <li>
                    <Link to="/platform/users/trade-following">Trade Followings Users</Link>
                  </li>
                  <li>
                    <Link to="/platform/spot-pairs">Spot Pairs</Link>
                  </li>
                  <li>
                    <Link to="/platform/spot-orders ">Spot Orders </Link>
                  </li>
                  <li>
                    <Link to="/platform/spot-trades">Spot Trades</Link>
                  </li>
                  <li>
                    <Link to="/platform/future-contracts">Future Contracts</Link>
                  </li>
                  <li>
                    <Link to="/platform/future-orders ">Future Orders </Link>
                  </li>
                  <li>
                    <Link to="/platform/future-positions">Future Positions</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>System</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/system/github-actions">Git Actions</Link>
                  </li>
                  <li>
                    <Link to="https://app.pulsetic.com/status/XUxaq4pz" target="_blank">
                      Apps Status
                    </Link>
                  </li>
                  <li>
                    <Link to="/system/logs">Logs</Link>
                  </li>
                  <li>
                    <Link to="/system/cron-jobs">Cron jobs</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Users</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/user/list">Users</Link>
                  </li>
                  <li>
                    <Link to="/user/invitations">Invitations</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Bonus Claims</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/bonus-claims">List</Link>
                  </li>
                  <li>
                    <Link to="/bonus-claims/payments">Payments</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Tournaments</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/tournaments">List</Link>
                  </li>
                  {/* <li>
                    <Link to="/tournaments/reporting">Reporting</Link>
                  </li> */}
                </ul>
              </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Exchange Partners</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/exchange-partners">Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/exchange-partners/list">List</Link>
                  </li>
                  <li>
                    <Link to="/exchange-partners/payments?status=PROCESSED">Payments</Link>
                  </li>
                </ul>
              </li>

              {/* <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-home-circle"></i>
                  <span>Cash-Back</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/cash-back">Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/cash-back/users">Users</Link>
                  </li>
                  <li>
                    <Link to="/cash-back/options">Options</Link>
                  </li>
                  <li>
                    <Link to="/cash-back/payments">Payments</Link>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}
