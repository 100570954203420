import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { FilterPlatformSpotPairItemResponseDto } from "../../lib/drivers/dto/platform-data/FilterPlatformSpotPairItemResponseDto";
import withRouterHook from "../../withRouterHook";

type MetaAdminListPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  symbol?: string;

  page: number;
  pageSize: number;
  total: number;
};

type MetaAdminListPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: FilterPlatformSpotPairItemResponseDto[];

  // filter
  filter: Filter;
};

class PlatformSpotPairPage extends React.Component<MetaAdminListPageProps, MetaAdminListPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: MetaAdminListPageState = {
    isLoading: true,
    startDate: new Date(),
    columns: [],
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);

    const filter: Filter = {
      symbol: queryParams.get("symbol") || "",
      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterPlatformSpotPairList({
      symbol:filter.symbol,
      limit: filter.pageSize,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      await this.setState((prevState) => {
        return {
          filter: filter,
          list: response.data,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: MetaAdminListPageProps, prevState: MetaAdminListPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Functions
  // private async loadData(): Promise<LogResponseDto> {
  //   const { currentPage, pageSize } = this.state;
  //   const offset = (currentPage - 1) * pageSize;

  //   const result = await PortCentralServer.Repo.client.filterLogList({
  //     limit: pageSize,
  //     offset: offset
  //   });

  //   return result;
  // }
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Platform" breadcrumbItem="Spot Pairs" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent setLoading={this.setLoading} />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Symbol</Label>
                                  <Input className="form-control" name="symbol" defaultValue={this.state.filter.symbol} />
                                </FormGroup>
                              </Col>

                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Symbol</th>
                                      <th>Quantity Precision</th>
                                      <th>PriceP recision</th>
                                      <th>Quote Asset Name</th>
                                      <th>Base Asset Name</th>
                                      <th>Base Asset</th>
                                      <th>Quote Asset</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: FilterPlatformSpotPairItemResponseDto, key: number) => (
                                      <tr key={key}>
                                        <td>{item.sysId.toString()}</td>
                                        <td>{item.symbol}</td>
                                        <td>{item.quantityPrecision}</td>
                                        <td>{item.pricePrecision}</td>
                                        <td>{item.quoteAssetName}</td>
                                        <td>{item.baseAssetName}</td>
                                        <td>{item.baseAsset}</td>
                                        <td>{item.quoteAsset}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(PlatformSpotPairPage);
