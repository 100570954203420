
export enum CentralServerPathParamKind {
    uid = "{uid}",
    tournamentId = "{tournamentId}",
    exchangePartnerId = "{exchangePartnerId}",
    year = "{year}",
    month = "{month}",
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // auth
    auth_sign_in = "auth/sign-in",
    auth_request_password_reset = "auth/request-password-reset",
    auth_reset_password = "auth/reset-password",
    auth_accept_invitation = "auth/accept-invitation",

    // profile
    profile_change_password = "profile/change-password",

    // system
    logs_filter = "logs/filter",
    tasks = "tasks",

    // user
    users_filter = "users/filter",
    users_invite = "users/invite",

    // platform
    platform_future_contracts = "platform-data/future-contracts",
    platform_users_filter = "platform-data/users/filter",
    platform_users_total = "platform-data/users/total",
    platform_users_invitations = "platform-data/users/invitations",
    platform_data_users_reporting_registrations_by_day = "platform-data/users/reporting/registrations-by-day",
    platform_data_users_spot_balances_filter = "platform-data/users/spot/balances/filter",
    platform_data_users_future_balances_filter = "platform-data/users/future/balances/filter",
    platform_users_trade_following = "platform-data/users/trade-following",
    platform_spot_pairs_filter = "platform-data/spot-pairs/filter",
    platform_spot_orders_filter = "platform-data/spot-orders/filter",
    platform_spot_trades_filter = "platform-data/spot-trades/filter",
    platform_future_orders_filter = "platform-data/future-orders/filter",
    platform_future_positions_filter = "platform-data/future-positions/filter",

    // tournament
    tournaments_filter = "tournaments/filter",
    tournament_by_id = "tournaments/{tournamentId}",
    tournament_references_filter = "tournaments/{tournamentId}/references/filter",
    tournament_references_count = "tournaments/{tournamentId}/references/count",
    tournament_references_reporting_enrollments_by_day = "tournaments/{tournamentId}/references/reporting/enrollments-by-day",
    tournament_orders_filter = "tournaments/{tournamentId}/orders/filter",

    // cash-back
    cash_back_user_references_filter = "cash-back/user-references/filter",
    cash_back_user_references_create = "cash-back/user-references",
    cash_back_user_references_update = "cash-back/user-references/{uid}",
    cash_back_user_references_get_by_id = "cash-back/user-references/{uid}",
    cash_back_user_references_not_assigned_filter = "cash-back/user-references/not-assigned/filter",

    // exchange-partners
    exchange_partner_filter = "exchange-partners/filter",
    exchange_partner_sync = "exchange-partners/sync",
    exchange_partner_index_exchangePartnerId_year_month = "exchange-partners/indexes/{exchangePartnerId}/{year}/{month}",
    exchange_partner_index_filter = "exchange-partners/indexes/filter",
    exchange_partner_index_set_payment_status = "exchange-partners/indexes/set-payment-status",
    exchange_partner_trading_distribution_filter = "exchange-partners/trading-distributions/filter",
    exchange_partners_indexes_totals_filter = "exchange-partners/indexes/totals/filter",
    exchange_partners_indexes_histories_filter = "exchange-partners/indexes/histories/filter",

    // common
    platform_data_common_spot_balance_totals = "platform-data/common/spot-balance-totals",
    platform_data_common_future_balance_totals = "platform-data/common/future-balance-totals",

    // bonus-claims
    bonuses_claims_filter = "bonuses/claims/filter",
    bonuses_claims_set_status = "bonuses/claims/set-status",
    bonuses_claims_payments_filter = "bonuses/claims/payments/filter",
    bonuses_claims_payments_set_status = "bonuses/claims/payments/set-status",
    bonuses_claims_payments_set_status_list = "bonuses/claims/payments/set-status-list",
}
