import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import { EnumBadgeControl } from "../../components/controls/EnumBadgeControl";
import { EnumSelectControl } from "../../components/controls/EnumSelectControl";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { BonusClaimDto } from "../../lib/drivers/dto/bonus/BonusClaimDto";
import { BonusClaimPaymentDto } from "../../lib/drivers/dto/bonus/BonusClaimPaymentDto";
import { SetBonusClaimPaymentStatusRequestDto } from "../../lib/drivers/dto/bonus/SetBonusClaimPaymentStatusRequestDto";
import { BonusClaimStatus } from "../../lib/drivers/dto/enums/BonusClaimStatus";
import { BonusPaymentStatus } from "../../lib/drivers/dto/enums/BonusPaymentStatus";
import { PlatformBonusType } from "../../lib/drivers/dto/enums/PlatformBonusType";
import withRouterHook from "../../withRouterHook";
import { ModalSetBonusClaimPaymentStatus, ModalSetBonusClaimPaymentStatusArgs } from "./ModalSetBonusClaimPaymentStatus";
import { ModalSetBonusClaimStatus, ModalSetBonusClaimStatusArgs } from "./ModalSetBonusClaimStatus";

// flatpickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { UidInfoButtons } from "../../components/controls/UidInfoButtons";

type BonusClaimListPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  uid?: string;
  bonusType?: PlatformBonusType;
  status?: BonusClaimStatus;
  startDate?: Date;
  endDate?: Date;
  page: number;
  pageSize: number;
  total: number;
};

type BonusClaimListPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: BonusClaimDto[];

  // filter
  filter: Filter;

  // functionality
  selectedPayments: { [key: string]: BonusClaimPaymentDto | undefined };
  selectedBonusClaim: BonusClaimDto;
  newBonusClaimStatus: BonusClaimStatus;
  newBonusClaimPaymentStatus: BonusPaymentStatus;

  // modals
  openModalSetBonusClaimStatus: boolean;
  openModalSetBonusClaimPaymentStatus: boolean;
};

class BonusClaimListPage extends React.Component<BonusClaimListPageProps, BonusClaimListPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: BonusClaimListPageState = {
    isLoading: true,
    startDate: new Date(),
    columns: [],
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    },

    // functionality
    selectedPayments: {},
    selectedBonusClaim: null,
    newBonusClaimStatus: BonusClaimStatus.NOT_SET,
    newBonusClaimPaymentStatus: BonusPaymentStatus.NOT_SET,

    // modals
    openModalSetBonusClaimStatus: false,
    openModalSetBonusClaimPaymentStatus: false
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);

    const filter: Filter = {
      uid: queryParams.get("uid") || null,
      bonusType: (queryParams.get("type") as PlatformBonusType) || null,
      status: (queryParams.get("status") as BonusClaimStatus) || null,
      startDate: queryParams.get("startDate") ? moment(queryParams.get("startDate")).toDate() : null,
      endDate: queryParams.get("endDate") ? moment(queryParams.get("endDate")).toDate() : null,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterBonusClaimList({
      uid: filter.uid,
      bonusType: filter.bonusType,
      status: filter.status,
      startDate: filter.startDate,
      endDate: filter.endDate,
      limit: filter.pageSize,
      offset: offset
    });

    console.log("response", response);

    if (response) {
      filter.total = response.total;

      await this.setState((prevState) => {
        return {
          filter: filter,
          list: response.data,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: BonusClaimListPageProps, prevState: BonusClaimListPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handlePaymentSelection = async (payment: BonusClaimPaymentDto) => {
    await this.setState((prevState) => {
      const current = prevState.selectedPayments[payment.bonusClaimPaymentId];
      const updatedSelections = { ...prevState.selectedPayments };

      if (current) {
        delete updatedSelections[payment.bonusClaimPaymentId]; // Deselecting the payment
      } else {
        updatedSelections[payment.bonusClaimPaymentId] = payment; // Selecting the payment
      }

      return { selectedPayments: updatedSelections };
    });
  };

  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Action Bonus Claim Status
  onActionSetBonusClaimStatusClick = async (e: React.FormEvent<HTMLButtonElement>, claim: BonusClaimDto, newStatus: BonusClaimStatus) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return { openModalSetBonusClaimStatus: true, selectedBonusClaim: claim, newBonusClaimStatus: newStatus };
    });
  };

  onModalSetBonusClaimStatusCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalSetBonusClaimStatus: false };
    });
  };

  onModalSetBonusClaimStatusSubmitClick = async (args: ModalSetBonusClaimStatusArgs) => {
    let success: boolean = true;

    if (success) {
      const updatedBonusClaim: BonusClaimDto = await PortCentralServer.Repo.client.setBonusClaimStatus({
        bonusClaimId: args.claim.bonusClaimId,
        status: args.claim.status,
        comment: args.claim.comment
      });

      await this.setState((prevState) => {
        return {
          openModalSetBonusClaimStatus: false,
          list: prevState.list.map((ele) => {
            if (ele.bonusClaimId == updatedBonusClaim.bonusClaimId) {
              ele = { ...updatedBonusClaim };
            }

            return ele;
          })
        };
      });
    }
  };
  // #endregion

  // #region Actions Bonus Claim Payment Status
  onActionSetBonusClaimPaymentStatusClick = async (e: React.FormEvent<HTMLButtonElement>, newStatus: BonusPaymentStatus) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return { openModalSetBonusClaimPaymentStatus: true, newBonusClaimPaymentStatus: newStatus };
    });
  };

  onModalSetBonusClaimPaymentStatusCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalSetBonusClaimPaymentStatus: false };
    });
  };

  onModalSetBonusClaimPaymentStatusSubmitClick = async (args: ModalSetBonusClaimPaymentStatusArgs) => {
    let success: boolean = true;

    if (success) {
      const payload: SetBonusClaimPaymentStatusRequestDto[] = [];

      for (const item of Object.values(this.state.selectedPayments)) {
        payload.push({
          bonusClaimPaymentId: item.bonusClaimPaymentId,
          status: this.state.newBonusClaimPaymentStatus,
          comment: args.comment
        });
      }

      const updatedRecords: BonusClaimPaymentDto[] = await PortCentralServer.Repo.client.setBonusClaimPaymentStatusList(payload);

      await this.setState((prevState) => {
        const newList = prevState.list.map((claim) => {
          if (claim.bonusClaimPayments) {
            const updatedPayments = claim.bonusClaimPayments.map((payment) => {
              const updatedPayment = updatedRecords.find((up) => up.bonusClaimPaymentId === payment.bonusClaimPaymentId);
              return updatedPayment ? { ...payment, ...updatedPayment } : payment;
            });
            return { ...claim, payments: updatedPayments };
          }
          return claim;
        });

        return {
          list: newList,
          selectedPayments: {},
          openModalSetBonusClaimPaymentStatus: false
        };
      });
    }
  };
  // #endregion

  // #region Functions
  hasSelectedPayments = () => {
    return Object.keys(this.state.selectedPayments).length > 0;
  };

  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Bonus Claims" breadcrumbItem="List" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent setLoading={this.setLoading} />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-0">
                                  <Label>Start Date</Label>
                                  <FlatPickr
                                    className="form-control"
                                    name="startDate"
                                    placeholder="Select time"
                                    value={this.state.filter.startDate}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      locale: {
                                        firstDayOfWeek: 1
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-0">
                                  <Label>End Date</Label>
                                  <FlatPickr
                                    className="form-control"
                                    name="endDate"
                                    placeholder="Select time"
                                    value={this.state.filter.endDate}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      locale: {
                                        firstDayOfWeek: 1
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Bonus Type</Label>
                                  <EnumSelectControl
                                    enumData={PlatformBonusType}
                                    name="type"
                                    selectedValue={this.state.filter.bonusType}
                                    className="form-control select2-search-disable"
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Status</Label>
                                  <EnumSelectControl
                                    enumData={BonusClaimStatus}
                                    name="status"
                                    selectedValue={this.state.filter.status}
                                    className="form-control select2-search-disable"
                                  />
                                </FormGroup>
                              </Col>

                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th colSpan={6}>Claim</th>
                                      <th colSpan={7}>Payments</th>
                                    </tr>
                                    <tr>
                                      <th></th>
                                      <th>uid</th>
                                      <th>status</th>
                                      <th>claimDate</th>
                                      <th>bonusType</th>
                                      <th>comment</th>
                                      <th className="nowrap actions">
                                        <button
                                          className="btn btn-sm btn-warning"
                                          disabled={!this.hasSelectedPayments()}
                                          onClick={(e) => this.onActionSetBonusClaimPaymentStatusClick(e, BonusPaymentStatus.PROCESSED)}
                                        >
                                          Approve
                                        </button>
                                        <button
                                          className="btn btn-sm btn-danger"
                                          disabled={!this.hasSelectedPayments()}
                                          onClick={(e) => this.onActionSetBonusClaimPaymentStatusClick(e, BonusPaymentStatus.REJECTED)}
                                        >
                                          Reject
                                        </button>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((itemClaim: BonusClaimDto, keyClaim: number) => (
                                      <tr key={keyClaim}>
                                        <td className="nowrap actions">
                                          {itemClaim.status == BonusClaimStatus.NOT_SET && (
                                            <>
                                              <button
                                                className="btn btn-sm btn-warning"
                                                onClick={(e) => this.onActionSetBonusClaimStatusClick(e, itemClaim, BonusClaimStatus.APPROVED)}
                                              >
                                                Approve
                                              </button>
                                              <button
                                                className="btn btn-sm btn-danger"
                                                onClick={(e) => this.onActionSetBonusClaimStatusClick(e, itemClaim, BonusClaimStatus.REJECTED)}
                                              >
                                                Reject
                                              </button>
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <UidInfoButtons uid={itemClaim.uid.toString()} />
                                        </td>
                                        <td>
                                          <EnumBadgeControl enumType="BonusClaimStatus" value={itemClaim.status} />
                                        </td>
                                        <td>{moment(itemClaim.claimDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        <td>
                                          <EnumBadgeControl enumType="PlatformBonusType" value={itemClaim.bonusType} />
                                        </td>

                                        <td>{itemClaim.comment}</td>
                                        {itemClaim.bonusClaimPayments && (
                                          <td>
                                            <Table className="table table-bordered m-0">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: "50px" }}></th>
                                                  <th style={{ width: "100px" }}>uid</th>
                                                  <th style={{ width: "50px" }}>status</th>
                                                  <th style={{ width: "100px" }}>statusDate</th>
                                                  <th style={{ width: "50px" }}>bonus</th>
                                                  <th style={{ width: "100px" }}>comment</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {(itemClaim.bonusClaimPayments || []).map((itemPayment: BonusClaimPaymentDto, keyPayment: number) => {
                                                  const isSelected = !!this.state.selectedPayments[itemPayment.bonusClaimPaymentId];
                                                  const rowClass = isSelected ? "table-warning" : "";

                                                  return (
                                                    <tr key={keyPayment}>
                                                      <td className={rowClass}>
                                                        {itemClaim.status == BonusClaimStatus.APPROVED &&
                                                          (itemPayment.status == BonusPaymentStatus.APPROVED || itemPayment.status == BonusPaymentStatus.NOT_SET) && (
                                                            <input type="checkbox" checked={isSelected} onChange={() => this.handlePaymentSelection(itemPayment)} />
                                                          )}
                                                      </td>
                                                      <td className={rowClass}>
                                                        <UidInfoButtons uid={itemPayment.uid.toString()} />
                                                      </td>
                                                      <td className={rowClass}>
                                                        <EnumBadgeControl enumType="BonusPaymentStatus" value={itemPayment.status} />
                                                      </td>
                                                      <td className={rowClass}>{moment(itemPayment.statusDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                      <td className={rowClass}>{itemPayment.bonus} USDT</td>
                                                      <td className={rowClass}>{itemPayment.comment}</td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </Table>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>

        {this.state.openModalSetBonusClaimStatus && (
          <ModalSetBonusClaimStatus
            onCloseClick={this.onModalSetBonusClaimStatusCloseClick}
            onSubmitClick={this.onModalSetBonusClaimStatusSubmitClick}
            claim={this.state.selectedBonusClaim}
            newStatus={this.state.newBonusClaimStatus}
          />
        )}

        {this.state.openModalSetBonusClaimPaymentStatus && (
          <ModalSetBonusClaimPaymentStatus
            onCloseClick={this.onModalSetBonusClaimPaymentStatusCloseClick}
            onSubmitClick={this.onModalSetBonusClaimPaymentStatusSubmitClick}
            selectedPayments={Object.values(this.state.selectedPayments)}
            newStatus={this.state.newBonusClaimPaymentStatus}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouterHook(BonusClaimListPage);
