import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { TournamentPlatformUserReferenceEnrollmentsByDayDto } from "../../lib/drivers/dto/tournament/TournamentPlatformUserReferenceEnrollmentsByDayDto";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ChartData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    fill: boolean;
    backgroundColor: string;
    borderColor: string;
  }>;
}

type TournamentReferencesByDayPageParams = ReturnType<typeof useParams> & {
  tournamentId: string;
};

export type TournamentReferencesByDayProps = {
  location?: ReturnType<typeof useLocation>;
  params?: TournamentReferencesByDayPageParams;
};

type TournamentReferencesByDayState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: TournamentPlatformUserReferenceEnrollmentsByDayDto[];
};

class TournamentReferencesByDayPage extends React.Component<TournamentReferencesByDayProps, TournamentReferencesByDayState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: TournamentReferencesByDayState = {
    isLoading: false,
    startDate: new Date(),
    columns: [],
    list: []
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    if (this.props.params.tournamentId) {
      const dataFilterLoggingResponseList = await this.loadData(this.props.params.tournamentId);

      await this.setState((prevState) => {
        return { list: dataFilterLoggingResponseList };
      });
    }
  };

  componentDidUpdate = async (prevProps: TournamentReferencesByDayProps, prevState: TournamentReferencesByDayState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleSelect(e: React.ChangeEvent<HTMLSelectElement>): void {
    // let search = e.target.value;
    // if (search === "All") {
    //   setOrder(orders)
    // } else {
    //   handleSearchData({ setState: setOrder, data: orders, item: search })
    // }
  }
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };

  setStartDate = async (date: Date) => {
    await this.setState((prevState) => {
      return {
        startDate: date
      };
    });
  };

  getChartData = (): ChartData => {
    const sortedList = _.orderBy(this.state.list, ['year', 'month', 'day'], ['asc', 'asc', 'asc']);

    const labels = sortedList.map(item => `${item.year}-${item.month}-${item.day}`);
    const data = sortedList.map(item => item.user_count);

    return {
      labels,
      datasets: [
        {
          label: "User Registrations",
          data: data,
          fill: false,
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgba(75, 192, 192, 0.2)"
        }
      ]
    };
  };
  // #endregion

  // #region Functions
  private async loadData(tournamentId: string): Promise<TournamentPlatformUserReferenceEnrollmentsByDayDto[]> {
    const result = await PortCentralServer.Repo.client.reportingTournamentReferencesByDayCount(tournamentId);

    return result;
  }
  // #endregion

  render() {
    const chartOptions = {
      scales: {
        x: {
          type: 'category' as const, // specifying 'category' as a literal type
          // additional configuration for the x-axis if needed
        },
        y: {
          // configuration for the y-axis
        }
      },
      // other chart options...
    };

    const sum = _.sumBy(this.state.list || [], "user_count");

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Tournament" breadcrumbItem={`Enrollments by day (${sum})`} />
              <Row>
                <Col xl={12}>
                  <Line data={this.getChartData()} options={chartOptions} />
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <div className="table-responsive">
                    <Table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th>Year</th>
                          <th>Month</th>
                          <th>Day</th>
                          <th>Registrations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.list || []).map((item: TournamentPlatformUserReferenceEnrollmentsByDayDto, key: number) => (
                          <tr key={key}>
                            <td>{item.year}</td>
                            <td>{item.month}</td>
                            <td>{item.day}</td>
                            <td>{item.user_count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(TournamentReferencesByDayPage);
