import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import moment from "moment";

import { TournamentDto } from "../lib/drivers/dto/tournament/TournamentDto";

export type TournamentDetailsComponentProps = {
  entity: TournamentDto;
};

type TournamentDetailsComponentState = {
  here: boolean;
};

export class TournamentDetailsComponent extends React.Component<TournamentDetailsComponentProps, TournamentDetailsComponentState> {
  state: TournamentDetailsComponentState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: TournamentDetailsComponentProps, prevState: TournamentDetailsComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col xl={12}>
                <div>
                  <h5>{this.props.entity.title}</h5>
                  <p>{this.props.entity.description}</p>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h5>Leverage</h5>
                <p>
                  {this.props.entity.reqMinLeverage ? <span>x{this.props.entity.reqMinLeverage}</span> : <span>--</span>}&nbsp;to&nbsp;
                  {this.props.entity.reqMaxLeverage ? <span>x{this.props.entity.reqMaxLeverage}</span> : <span>--</span>}
                </p>
              </Col>

              <Col>
                <h5>Position</h5>
                <p>
                  {this.props.entity.reqMinPosition ? <span>{this.props.entity.reqMinPosition} USDT</span> : <span>--</span>}&nbsp;to&nbsp;
                  {this.props.entity.reqMaxPosition ? <span>{this.props.entity.reqMaxPosition} USDT</span> : <span>--</span>}
                </p>
              </Col>

              <Col>
                <h5>Total Trades</h5>
                <p>
                  {this.props.entity.reqMinTotalTrades ? <span>{this.props.entity.reqMinTotalTrades}</span> : <span>--</span>}&nbsp;to&nbsp;
                  {this.props.entity.reqMaxTotalTrades ? <span>{this.props.entity.reqMaxTotalTrades}</span> : <span>--</span>}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <div>
                  <h5>
                    <i className="bx bx-calendar"></i> Duration
                  </h5>
                  <p>
                    {moment(this.props.entity.startDate).format("YYYY-MM-DD HH:mm:ss")} - {moment(this.props.entity.endDate).format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                </div>
              </Col>
              <Col>
                <div>
                  <h5>
                    <i className="bx bx-calendar"></i> Enrollment
                  </h5>
                  <p>
                    {moment(this.props.entity.enrollStartDate).format("YYYY-MM-DD HH:mm:ss")} - {moment(this.props.entity.enrollEndDate).format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
