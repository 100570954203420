import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { PathParamPair } from '../core/ServerDriver/UrlParamPair';
import { CentralServerEndpointKind, CentralServerPathParamKind } from "./CentralServerClientEnums";
import { AcceptInvitationRequestDto } from './dto/auth/AcceptInvitationRequestDto';
import { AcceptInvitationResponseDto } from './dto/auth/AcceptInvitationResponseDto';
import { ChangePasswordRequestDto } from './dto/auth/ChangePasswordRequestDto';
import { ChangePasswordResponseDto } from './dto/auth/ChangePasswordResponseDto';
import { CreatePasswordResetDto } from "./dto/auth/CreatePasswordResetDto";
import { CreatePasswordResetResponseDto } from './dto/auth/CreatePasswordResetResponseDto';
import { PasswordResetRequestDto } from "./dto/auth/PasswordResetRequestDto";
import { PasswordResetResponseDto } from './dto/auth/PasswordResetResponseDto';
import { SignInDto } from "./dto/auth/SignInDto";
import { SignInResponseDto } from './dto/auth/SignInResponseDto';
import { BonusClaimDto } from './dto/bonus/BonusClaimDto';
import { BonusClaimPaymentDto } from './dto/bonus/BonusClaimPaymentDto';
import { FilterBonusClaimPaymentRequestDto } from './dto/bonus/FilterBonusClaimPaymentRequestDto';
import { FilterBonusClaimPaymentResponseDto } from './dto/bonus/FilterBonusClaimPaymentResponseDto';
import { FilterBonusClaimRequestDto } from './dto/bonus/FilterBonusClaimRequestDto';
import { FilterBonusClaimResponseDto } from './dto/bonus/FilterBonusClaimResponseDto';
import { SetBonusClaimPaymentStatusRequestDto } from './dto/bonus/SetBonusClaimPaymentStatusRequestDto';
import { SetBonusClaimStatusRequestDto } from './dto/bonus/SetBonusClaimStatusRequestDto';
import { CashBackUserReferenceDto } from './dto/cash-back/CashBackUserReferenceDto';
import { CreateCashBackUserReferenceDto } from './dto/cash-back/CreateCashBackUserReferenceDto';
import { FilterCashBackUserReferenceNotAssignedRequestDto } from './dto/cash-back/FilterCashBackUserReferenceNotAssignedRequestDto';
import { FilterCashBackUserReferenceNotAssignedResponseDto } from './dto/cash-back/FilterCashBackUserReferenceNotAssignedResponseDto';
import { FilterCashBackUserReferenceRequestDto } from './dto/cash-back/FilterCashBackUserReferenceRequestDto';
import { FilterCashBackUserReferenceResponseDto } from './dto/cash-back/FilterCashBackUserReferenceResponseDto';
import { UpdateCashBackUserReferenceDto } from './dto/cash-back/UpdateCashBackUserReferenceDto';
import { ExchangePartnerIndexDto } from './dto/exchange-partners/ExchangePartnerIndexDto';
import { ExchangePartnerIndexPaymentStatusRequestDto } from './dto/exchange-partners/ExchangePartnerIndexPaymentStatusRequestDto';
import { FilterExchangePartnerIndexRequestDto } from './dto/exchange-partners/FilterExchangePartnerIndexRequestDto';
import { FilterExchangePartnerIndexResponseDto } from './dto/exchange-partners/FilterExchangePartnerIndexResponseDto';
import { FilterExchangePartnerRequestDto } from './dto/exchange-partners/FilterExchangePartnerRequestDto';
import { FilterExchangePartnerResponseDto } from './dto/exchange-partners/FilterExchangePartnerResponseDto';
import { FilterExchangePartnerTradingDistributionRequestDto } from './dto/exchange-partners/FilterExchangePartnerTradingDistributionRequestDto';
import { FilterExchangePartnerTradingDistributionResponseDto } from './dto/exchange-partners/FilterExchangePartnerTradingDistributionResponseDto';
import { SyncExchangePartnerRequestDto } from './dto/exchange-partners/SyncExchangePartnerRequestDto';
import { SyncExchangePartnerResponseDto } from './dto/exchange-partners/SyncExchangePartnerResponseDto';
import { FilterPlatformFutureOrderRequestDto } from './dto/platform-data/FilterPlatformFutureOrderRequestDto';
import { FilterPlatformFutureOrderResponseDto } from './dto/platform-data/FilterPlatformFutureOrderResponseDto';
import { FilterPlatformFuturePositionRequestDto } from './dto/platform-data/FilterPlatformFuturePositionRequestDto';
import { FilterPlatformFuturePositionResponseDto } from './dto/platform-data/FilterPlatformFuturePositionResponseDto';
import { FilterPlatformSpotOrderRequestDto } from './dto/platform-data/FilterPlatformSpotOrderRequestDto';
import { FilterPlatformSpotOrderResponseDto } from './dto/platform-data/FilterPlatformSpotOrderResponseDto';
import { FilterPlatformSpotPairRequestDto } from './dto/platform-data/FilterPlatformSpotPairRequestDto';
import { FilterPlatformSpotPairResponseDto } from './dto/platform-data/FilterPlatformSpotPairResponseDto';
import { FilterPlatformSpotTradeRequestDto } from './dto/platform-data/FilterPlatformSpotTradeRequestDto';
import { FilterPlatformSpotTradeResponseDto } from './dto/platform-data/FilterPlatformSpotTradeResponseDto';
import { FilterPlatformUserInvitationRequestDto } from './dto/platform-data/FilterPlatformUserInvitationRequestDto';
import { FilterPlatformUserInvitationResponseDto } from './dto/platform-data/FilterPlatformUserInvitationResponseDto';
import { FilterPlatformUserRequestDto } from './dto/platform-data/FilterPlatformUserRequestDto';
import { FilterPlatformUserResponseDto } from './dto/platform-data/FilterPlatformUserResponseDto';
import { FilterPlatformUserTradeFollowingRequestDto } from './dto/platform-data/FilterPlatformUserTradeFollowingRequestDto';
import { FilterPlatformUserTradeFollowingResponseDto } from './dto/platform-data/FilterPlatformUserTradeFollowingResponseDto';
import { PlatformFutureContractResponseDto } from './dto/platform-data/PlatformFutureContractResponseDto';
import { PlatformUserRegistrationsByDayDto } from './dto/platform-data/PlatformUserRegistrationsByDayDto';
import { ViewPlatformUserFutureBalanceTotalDto } from './dto/platform-data/ViewPlatformUserFutureBalanceTotalDto';
import { ViewPlatformUserSpotBalanceTotalDto } from './dto/platform-data/ViewPlatformUserSpotBalanceTotalDto';
import { FilterAdminRequestDto } from './dto/system/FilterAdminRequestDto';
import { FilterAdminResponseDto } from './dto/system/FilterAdminResponseDto';
import { LogRequestDto } from './dto/system/LogRequestDto';
import { LogResponseDto } from './dto/system/LogResponseDto';
import { TaskResponseDto } from './dto/system/TaskResponseDto';
import { FilterTournamentPlatformUserOrderReferenceRequestDto } from './dto/tournament/FilterTournamentPlatformUserOrderReferenceRequestDto';
import { FilterTournamentPlatformUserOrderReferenceResponseDto } from './dto/tournament/FilterTournamentPlatformUserOrderReferenceResponseDto';
import { FilterTournamentPlatformUserReferenceRequestDto } from './dto/tournament/FilterTournamentPlatformUserReferenceRequestDto';
import { FilterTournamentPlatformUserReferenceResponseDto } from './dto/tournament/FilterTournamentPlatformUserReferenceResponseDto';
import { FilterTournamentRequestDto } from './dto/tournament/FilterTournamentRequestDto';
import { FilterTournamentResponseDto } from './dto/tournament/FilterTournamentResponseDto';
import { TournamentDto } from './dto/tournament/TournamentDto';
import { TournamentPlatformUserReferenceEnrollmentsByDayDto } from './dto/tournament/TournamentPlatformUserReferenceEnrollmentsByDayDto';
import { FilterPlatformUserSpotBalanceRequestDto } from './dto/platform-data/FilterPlatformUserSpotBalanceRequestDto';
import { FilterPlatformUserSpotBalanceResponseDto } from './dto/platform-data/FilterPlatformUserSpotBalanceResponseDto';
import { FilterPlatformUserFutureBalanceRequestDto } from './dto/platform-data/FilterPlatformUserFutureBalanceRequestDto';
import { FilterPlatformUserFutureBalanceResponseDto } from './dto/platform-data/FilterPlatformUserFutureBalanceResponseDto';
import { FilterViewExchangePartnerIndexesTotalRequestDto } from './dto/exchange-partners/FilterViewExchangePartnerIndexesTotalRequestDto';
import { FilterViewExchangePartnerIndexesTotalResponseDto } from './dto/exchange-partners/FilterViewExchangePartnerIndexesTotalResponseDto';
import { FilterExchangePartnerIndexHistoryRequestDto } from './dto/exchange-partners/FilterExchangePartnerIndexHistoryRequestDto';
import { FilterExchangePartnerIndexHistoryResponseDto } from './dto/exchange-partners/FilterExchangePartnerIndexHistoryResponseDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_CENTRAL_BASE_URL);
    }
    // #endregion

    // #region Auth Endpoints
    async authSignIn(model: SignInDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authRequestPasswordReset(model: CreatePasswordResetDto): Promise<CreatePasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_request_password_reset.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CreatePasswordResetResponseDto = data as CreatePasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authResetPassword(model: PasswordResetRequestDto): Promise<PasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_reset_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: PasswordResetResponseDto = data as PasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authAcceptInvitation(model: AcceptInvitationRequestDto): Promise<AcceptInvitationResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_accept_invitation.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AcceptInvitationResponseDto = data as AcceptInvitationResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Profile Endpoints
    async profileChangePassword(model: ChangePasswordRequestDto): Promise<ChangePasswordResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_change_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ChangePasswordResponseDto = data as ChangePasswordResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region System Endpoints
    async filterLogList(model: LogRequestDto): Promise<LogResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.logs_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: LogResponseDto = data as LogResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getTaskList(): Promise<TaskResponseDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tasks.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: TaskResponseDto[] = data as TaskResponseDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    // #endregion

    // #region Platform Endpoints
    async getPlatformFutureContractList(): Promise<PlatformFutureContractResponseDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_future_contracts.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: PlatformFutureContractResponseDto[] = data as PlatformFutureContractResponseDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformUserTotal(): Promise<number> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_users_total.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: number = data as number;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformUserList(model: FilterPlatformUserRequestDto): Promise<FilterPlatformUserResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_users_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformUserResponseDto = data as FilterPlatformUserResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformUserInvitationList(model: FilterPlatformUserInvitationRequestDto): Promise<FilterPlatformUserInvitationResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_users_invitations.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformUserInvitationResponseDto = data as FilterPlatformUserInvitationResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async reportingPlatformUserRegistrationByDayList(): Promise<PlatformUserRegistrationsByDayDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_data_users_reporting_registrations_by_day.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: PlatformUserRegistrationsByDayDto[] = data as PlatformUserRegistrationsByDayDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformUserSpotBalanceList(model: FilterPlatformUserSpotBalanceRequestDto): Promise<FilterPlatformUserSpotBalanceResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_data_users_spot_balances_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformUserSpotBalanceResponseDto = data as FilterPlatformUserSpotBalanceResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformUserFutureBalanceList(model: FilterPlatformUserFutureBalanceRequestDto): Promise<FilterPlatformUserFutureBalanceResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_data_users_future_balances_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformUserFutureBalanceResponseDto = data as FilterPlatformUserFutureBalanceResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformUserTradeFollowingList(model: FilterPlatformUserTradeFollowingRequestDto): Promise<FilterPlatformUserTradeFollowingResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_users_trade_following.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformUserTradeFollowingResponseDto = data as FilterPlatformUserTradeFollowingResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformSpotPairList(model: FilterPlatformSpotPairRequestDto): Promise<FilterPlatformSpotPairResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_spot_pairs_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformSpotPairResponseDto = data as FilterPlatformSpotPairResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformSpotOrderList(model: FilterPlatformSpotOrderRequestDto): Promise<FilterPlatformSpotOrderResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_spot_orders_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformSpotOrderResponseDto = data as FilterPlatformSpotOrderResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformSpotTradeList(model: FilterPlatformSpotTradeRequestDto): Promise<FilterPlatformSpotTradeResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_spot_trades_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformSpotTradeResponseDto = data as FilterPlatformSpotTradeResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    async filterPlatformFutureOrderList(model: FilterPlatformFutureOrderRequestDto): Promise<FilterPlatformFutureOrderResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_future_orders_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformFutureOrderResponseDto = data as FilterPlatformFutureOrderResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterPlatformFuturePositionList(model: FilterPlatformFuturePositionRequestDto): Promise<FilterPlatformFuturePositionResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_future_positions_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterPlatformFuturePositionResponseDto = data as FilterPlatformFuturePositionResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Platform Data Common
    async getPlatformUserSpotBalanceTotals(): Promise<ViewPlatformUserSpotBalanceTotalDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_data_common_spot_balance_totals.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: ViewPlatformUserSpotBalanceTotalDto[] = data as ViewPlatformUserSpotBalanceTotalDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getPlatformUserFutureBalanceTotals(): Promise<ViewPlatformUserFutureBalanceTotalDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.platform_data_common_future_balance_totals.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: ViewPlatformUserFutureBalanceTotalDto[] = data as ViewPlatformUserFutureBalanceTotalDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Users
    async filterUsers(model: FilterAdminRequestDto): Promise<FilterAdminResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterAdminResponseDto = data as FilterAdminResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Tournaments
    async getTournament(tournamentId: string): Promise<TournamentDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournament_by_id.toString(), [
                new PathParamPair(CentralServerPathParamKind.tournamentId, tournamentId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: TournamentDto = data as TournamentDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterTournaments(model: FilterTournamentRequestDto): Promise<FilterTournamentResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournaments_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterTournamentResponseDto = data as FilterTournamentResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterTournamentReferences(model: FilterTournamentPlatformUserReferenceRequestDto): Promise<FilterTournamentPlatformUserReferenceResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournament_references_filter.toString(), [
                new PathParamPair(CentralServerPathParamKind.tournamentId, model.tournamentId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterTournamentPlatformUserReferenceResponseDto = data as FilterTournamentPlatformUserReferenceResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getTournamentReferencesCount(model: FilterTournamentPlatformUserReferenceRequestDto): Promise<number> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournament_references_count.toString(), [
                new PathParamPair(CentralServerPathParamKind.tournamentId, model.tournamentId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: number = data as number;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async reportingTournamentReferencesByDayCount(tournamentId: string): Promise<TournamentPlatformUserReferenceEnrollmentsByDayDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournament_references_reporting_enrollments_by_day.toString(), [
                new PathParamPair(CentralServerPathParamKind.tournamentId, tournamentId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: TournamentPlatformUserReferenceEnrollmentsByDayDto[] = data as TournamentPlatformUserReferenceEnrollmentsByDayDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterTournamentOrders(model: FilterTournamentPlatformUserOrderReferenceRequestDto): Promise<FilterTournamentPlatformUserOrderReferenceResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.tournament_orders_filter.toString(), [
                new PathParamPair(CentralServerPathParamKind.tournamentId, model.tournamentId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterTournamentPlatformUserOrderReferenceResponseDto = data as FilterTournamentPlatformUserOrderReferenceResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Cash-Back Endpoints
    async filterCashBackUserReferenceList(model: FilterCashBackUserReferenceRequestDto): Promise<FilterCashBackUserReferenceResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.cash_back_user_references_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterCashBackUserReferenceResponseDto = data as FilterCashBackUserReferenceResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterCashBackUserReferenceNotAssignedList(model: FilterCashBackUserReferenceNotAssignedRequestDto): Promise<FilterCashBackUserReferenceNotAssignedResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.cash_back_user_references_not_assigned_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterCashBackUserReferenceNotAssignedResponseDto = data as FilterCashBackUserReferenceNotAssignedResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async createCashBackUserReference(model: CreateCashBackUserReferenceDto): Promise<CashBackUserReferenceDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.cash_back_user_references_create.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CashBackUserReferenceDto = data as CashBackUserReferenceDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async updateCashBackUserReference(uid: string, model: UpdateCashBackUserReferenceDto): Promise<CashBackUserReferenceDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.cash_back_user_references_update.toString(), [
                new PathParamPair(CentralServerPathParamKind.uid, uid)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: CashBackUserReferenceDto = data as CashBackUserReferenceDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Exchange Partners Endpoints
    async filterExchangePartnerList(model: FilterExchangePartnerRequestDto): Promise<FilterExchangePartnerResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partner_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterExchangePartnerResponseDto = data as FilterExchangePartnerResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterExchangePartnerIndexList(model: FilterExchangePartnerIndexRequestDto): Promise<FilterExchangePartnerIndexResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partner_index_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterExchangePartnerIndexResponseDto = data as FilterExchangePartnerIndexResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterExchangePartnerTradingDistributionList(model: FilterExchangePartnerTradingDistributionRequestDto): Promise<FilterExchangePartnerTradingDistributionResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partner_trading_distribution_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterExchangePartnerTradingDistributionResponseDto = data as FilterExchangePartnerTradingDistributionResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterViewExchangePartnerIndexesTotalRequest(model: FilterViewExchangePartnerIndexesTotalRequestDto): Promise<FilterViewExchangePartnerIndexesTotalResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partners_indexes_totals_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewExchangePartnerIndexesTotalResponseDto = data as FilterViewExchangePartnerIndexesTotalResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterExchangePartnerIndexHistory(model: FilterExchangePartnerIndexHistoryRequestDto): Promise<FilterExchangePartnerIndexHistoryResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partners_indexes_histories_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterExchangePartnerIndexHistoryResponseDto = data as FilterExchangePartnerIndexHistoryResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async syncExchangePartnerIndex(model: SyncExchangePartnerRequestDto): Promise<SyncExchangePartnerResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partner_sync.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.put(url, model, headers);
            const result: SyncExchangePartnerResponseDto = data as SyncExchangePartnerResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getExchangePartnerIndexByPeriod(exchangePartnerId: string, year: number, month: number): Promise<ExchangePartnerIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partner_index_exchangePartnerId_year_month.toString(), [
                new PathParamPair(CentralServerPathParamKind.exchangePartnerId, exchangePartnerId),
                new PathParamPair(CentralServerPathParamKind.year, year.toString()),
                new PathParamPair(CentralServerPathParamKind.month, month.toString())
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            const result: ExchangePartnerIndexDto = data as ExchangePartnerIndexDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setExchangePartnerIndexPaymentStatus(model: ExchangePartnerIndexPaymentStatusRequestDto): Promise<ExchangePartnerIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.exchange_partner_index_set_payment_status.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ExchangePartnerIndexDto = data as ExchangePartnerIndexDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Bonus claim
    async filterBonusClaimList(model: FilterBonusClaimRequestDto): Promise<FilterBonusClaimResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.bonuses_claims_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterBonusClaimResponseDto = data as FilterBonusClaimResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterBonusClaimPaymentList(model: FilterBonusClaimPaymentRequestDto): Promise<FilterBonusClaimPaymentResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.bonuses_claims_payments_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterBonusClaimPaymentResponseDto = data as FilterBonusClaimPaymentResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setBonusClaimStatus(model: SetBonusClaimStatusRequestDto): Promise<BonusClaimDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.bonuses_claims_set_status.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: BonusClaimDto = data as BonusClaimDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setBonusClaimPaymentStatus(model: SetBonusClaimPaymentStatusRequestDto): Promise<BonusClaimPaymentDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.bonuses_claims_payments_set_status.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: BonusClaimPaymentDto = data as BonusClaimPaymentDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setBonusClaimPaymentStatusList(model: SetBonusClaimPaymentStatusRequestDto[]): Promise<BonusClaimPaymentDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.bonuses_claims_payments_set_status_list.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: BonusClaimPaymentDto[] = data as BonusClaimPaymentDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}