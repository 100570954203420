import moment from "moment";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { PlatformUserVerifiedStatus } from "../../lib/drivers/dto/enums/PlatformUserVerifiedStatus";
import { PlatformUserDto } from "../../lib/drivers/dto/platform-data/PlatformUserDto";
import withRouterHook from "../../withRouterHook";

// flatpickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { UidInfoButtons } from "../../components/controls/UidInfoButtons";

type PlatformUserPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  uid?: string;
  cloudId?: string;
  nickname?: string;
  mobileNumber?: string;
  email?: string;
  inviteCode?: string;
  invitedUid?: string;
  startDate?: Date;
  endDate?: Date;
  page: number;
  pageSize: number;
  total: number;
};

type PlatformUserPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: PlatformUserDto[];

  // filter
  filter: Filter;
};

class PlatformUserPage extends React.Component<PlatformUserPageProps, PlatformUserPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PlatformUserPageState = {
    isLoading: true,
    startDate: new Date(),
    columns: [],
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);

    const filter: Filter = {
      uid: queryParams.get("uid") || null,
      cloudId: queryParams.get("cloudId") || null,
      nickname: queryParams.get("nickname") || "",
      mobileNumber: queryParams.get("mobileNumber") || "",
      email: queryParams.get("email") || "",
      inviteCode: queryParams.get("inviteCode") || "",
      invitedUid: queryParams.get("invitedUid") || "",
      startDate: queryParams.get("startDate") ? moment(queryParams.get("startDate")).toDate() : null,
      endDate: queryParams.get("endDate") ? moment(queryParams.get("endDate")).toDate() : null,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterPlatformUserList({
      uid: filter.uid,
      cloudId: filter.cloudId,
      nickname: filter.nickname,
      mobileNumber: filter.mobileNumber,
      email: filter.email,
      inviteCode: filter.inviteCode,
      invitedUid: filter.invitedUid,
      startDate: filter.startDate,
      endDate: filter.endDate,
      limit: filter.pageSize,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      await this.setState((prevState) => {
        return {
          filter: filter,
          list: response.data,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: PlatformUserPageProps, prevState: PlatformUserPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };
  // #endregion

  // #region Functions
  // private async loadData(): Promise<LogResponseDto> {
  //   const { currentPage, pageSize } = this.state;
  //   const offset = (currentPage - 1) * pageSize;

  //   const result = await PortCentralServer.Repo.client.filterLogList({
  //     limit: pageSize,
  //     offset: offset
  //   });

  //   return result;
  // }

  badgeForVerifiedStatus(verifiedStatus: PlatformUserVerifiedStatus) {
    let color = "";

    switch (verifiedStatus) {
      case PlatformUserVerifiedStatus.NOT_SET:
        color = "warning";
        break;
      case PlatformUserVerifiedStatus.APPROVED:
        color = "primary";
        break;
      case PlatformUserVerifiedStatus.REJECTED:
        color = "danger";
        break;

      default:
        break;
    }

    return <span className={`badge bg-${color}`}>{verifiedStatus}</span>;
  }
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Platform" breadcrumbItem="Users" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent setLoading={this.setLoading} />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-0">
                                  <Label>Start Date</Label>
                                  <FlatPickr
                                    className="form-control"
                                    name="startDate"
                                    placeholder="Select time"
                                    value={this.state.filter.startDate}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      locale: {
                                        firstDayOfWeek: 1
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-0">
                                  <Label>End Date</Label>
                                  <FlatPickr
                                    className="form-control"
                                    name="endDate"
                                    placeholder="Select time"
                                    value={this.state.filter.endDate}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      locale: {
                                        firstDayOfWeek: 1
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">UID</Label>
                                  <Input className="form-control" name="uid" defaultValue={this.state.filter.uid} />
                                </FormGroup>
                              </Col>
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">CloudId</Label>
                                  <Input className="form-control" name="cloudId" defaultValue={this.state.filter.cloudId} />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Nickname</Label>
                                  <Input className="form-control" name="nickname" defaultValue={this.state.filter.nickname} />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Mobile Number</Label>
                                  <Input className="form-control" name="mobileNumber" defaultValue={this.state.filter.mobileNumber} />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">email</Label>
                                  <Input className="form-control" name="email" defaultValue={this.state.filter.email} />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">inviteCode</Label>
                                  <Input className="form-control" name="inviteCode" defaultValue={this.state.filter.inviteCode} />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">invitedUid</Label>
                                  <Input className="form-control" name="invitedUid" defaultValue={this.state.filter.invitedUid} />
                                </FormGroup>
                              </Col>

                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th>UID</th>
                                      <th>CloudId</th>
                                      <th>Email</th>
                                      <th>Nickname</th>
                                      <th>Mobile Number</th>
                                      <th>Ctime</th>
                                      <th>InvitedUid</th>
                                      <th>Invited Nickname</th>
                                      <th>Invited Code</th>
                                      <th>Verified</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: PlatformUserDto, key: number) => (
                                      <tr key={key}>
                                        <td>
                                          <UidInfoButtons uid={item.uid} />
                                        </td>
                                        <td>{item.cloudId.toString()}</td>
                                        <td>{item.email}</td>
                                        <td>{item.nickname}</td>
                                        <td>{item.mobileNumber}</td>
                                        <td>{moment(item.ctime).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        <td>
                                          <UidInfoButtons uid={item.invitedUid} />
                                        </td>
                                        <td>{item.invitedNickname}</td>
                                        <td>{item.invitedCode}</td>
                                        <td>{this.badgeForVerifiedStatus(item.verifiedStatus)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(PlatformUserPage);
