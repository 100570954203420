export enum TournamentEnrollmentStatus {
    // User has started the enrollment process but hasn't completed it
    PENDING = "PENDING",
    
    // User has completed the enrollment process and is an active participant
    ENROLLED = "ENROLLED",
    
    // User is on a waitlist due to participant limits
    WAITLISTED = "WAITLISTED",
    
    // User has been removed from the tournament for rule violations or other reasons
    DISQUALIFIED = "DISQUALIFIED",
    
    // User has voluntarily opted out of the tournament
    WITHDRAWN = "WITHDRAWN",
    
    // User has finished participation in the tournament
    COMPLETED = "COMPLETED",
    
    // User's participation is temporarily halted, possibly due to ongoing investigations
    SUSPENDED = "SUSPENDED",
    
    // User is enrolled but hasn't engaged in tournament activities for a certain period
    INACTIVE = "INACTIVE"
}
