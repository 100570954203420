import React from "react";
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import moment from "moment";
import _ from "lodash";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import LoaderComponent from "../../components/LoaderComponent";
import { ViewPlatformUserFutureBalanceTotalDto } from "../../lib/drivers/dto/platform-data/ViewPlatformUserFutureBalanceTotalDto";
import { ViewPlatformUserSpotBalanceTotalDto } from "../../lib/drivers/dto/platform-data/ViewPlatformUserSpotBalanceTotalDto";
import { Link } from "react-router-dom";

export type PlatformBalanceTotalPageProps = {};

type PlatformBalanceTotalPageState = {
  isLoading: boolean;
  startDate: Date;

  futureBalance: ViewPlatformUserFutureBalanceTotalDto;
  listSpotBalances: ViewPlatformUserSpotBalanceTotalDto[];
};

class PlatformBalanceTotalPage extends React.Component<PlatformBalanceTotalPageProps, PlatformBalanceTotalPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PlatformBalanceTotalPageState = {
    isLoading: true,
    startDate: new Date(),
    futureBalance: null,
    listSpotBalances: []
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    const dataSpotBalanceList = await this.loadSpotBalanceList();
    const futureBalance = await this.loadFutureBalance();

    await this.setState((prevState) => {
      return {
        listSpotBalances: dataSpotBalanceList,
        futureBalance: futureBalance,
        isLoading: false
      };
    });
  };

  componentDidUpdate = async (prevProps: PlatformBalanceTotalPageProps, prevState: PlatformBalanceTotalPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleSelect(e: React.ChangeEvent<HTMLSelectElement>): void {
    // let search = e.target.value;
    // if (search === "All") {
    //   setOrder(orders)
    // } else {
    //   handleSearchData({ setState: setOrder, data: orders, item: search })
    // }
  }
  // #endregion

  // #region State Setters
  setLoading = async (loading: boolean) => {
    await this.setState((prevState) => {
      return {
        isLoading: loading
      };
    });
  };

  // #endregion

  // #region Functions
  private async loadSpotBalanceList(): Promise<ViewPlatformUserSpotBalanceTotalDto[]> {
    const result = await PortCentralServer.Repo.client.getPlatformUserSpotBalanceTotals();

    return _.orderBy(result, ["allBtcValuatin"], ["desc"]);
  }

  private async loadFutureBalance(): Promise<ViewPlatformUserFutureBalanceTotalDto> {
    const result: ViewPlatformUserFutureBalanceTotalDto[] = await PortCentralServer.Repo.client.getPlatformUserFutureBalanceTotals();

    return result[0];
  }
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Common" breadcrumbItem="Balance" />

              {this.state.isLoading ? (
                <LoaderComponent setLoading={this.setLoading} />
              ) : (
                <>
                  <Row>
                    <Col xl={12}>
                      <Card>
                        <CardTitle>Future Balance</CardTitle>
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table table-bordered m-0">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>totalBalance</th>
                                  <th>crossBalance</th>
                                  <th>isolatedBalance</th>
                                  <th>frozenBalance</th>
                                </tr>
                              </thead>
                              {this.state.futureBalance && (
                                <tbody>
                                  <tr>
                                    <td>USDT</td>
                                    <td>{this.state.futureBalance.totalBalance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{this.state.futureBalance.crossBalance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{this.state.futureBalance.isolatedBalance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{this.state.futureBalance.frozenBalance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                  </tr>
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={12}>
                      <Card>
                        <CardTitle>Spot Balance</CardTitle>
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table table-bordered m-0">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th>Name</th>
                                  <th>allBalance</th>
                                  <th>allBtcValuatin</th>
                                  <th>btcValuatin</th>
                                  <th>normal_balance</th>
                                  <th>lock_balance</th>
                                  <th>total_balance</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {(this.state.listSpotBalances || []).map((item: ViewPlatformUserSpotBalanceTotalDto, key: number) => (
                                  <tr key={key}>
                                    <td>
                                      <Link className="btn btn-sm btn-primary" to={`/platform/users/spot-balance?coin=${item.showName}`}>
                                        Balance
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="avatar-sm bg-light rounded p-2">
                                        <img className="img-fluid rounded-circle" src={item.icon} alt={item.showName} />
                                      </div>
                                    </td>
                                    <td>{item.showName}</td>
                                    <td>{item.allBalance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{item.allBtcValuatin.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{item.btcValuatin.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{item.normal_balance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{item.lock_balance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                    <td>{item.total_balance.toLocaleString("en-US", { maximumFractionDigits: 8 })}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(PlatformBalanceTotalPage);
