

export enum ExchangePartnerIndexStatus {
    NOT_SET = "NOT_SET",
    PROCESSING = "PROCESSING", // the current index is being processed
    PROCESSED = "PROCESSED", // the index is processed and ready for admin confirmation
    CONFIRMED = "CONFIRMED", // the partner profit is confirmed and waits payment. No more sync
    PAID = "PAID", // the partner profit is paid. No more sync
    BLOCKED = "BLOCKED", // the partners is blocked for payment for the current index
}
