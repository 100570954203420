import React from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";

export type TournamentEditPageProps = {};

type TournamentEditPageState = {
  here: boolean;
};

class TournamentEditPage extends React.Component<TournamentEditPageProps, TournamentEditPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: TournamentEditPageState = {
    here: true,
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: TournamentEditPageProps, prevState: TournamentEditPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h1>This is TournamentEditPage {this.state.here}</h1>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(TournamentEditPage);
