import React from "react";
import { Col, Row } from "reactstrap";
import { BaseModal } from "../../components/modals/BaseModal";
import { BonusClaimPaymentDto } from "../../lib/drivers/dto/bonus/BonusClaimPaymentDto";
import { BonusPaymentStatus } from "../../lib/drivers/dto/enums/BonusPaymentStatus";

interface SummedBonuses {
  [uid: string]: number;
}

export type ModalSetBonusClaimPaymentStatusArgs = {
  comment: string;
};

export type ModalSetBonusClaimPaymentStatusProps = {
  selectedPayments: BonusClaimPaymentDto[];
  newStatus: BonusPaymentStatus;
  onCloseClick(): void;
  onSubmitClick(args: ModalSetBonusClaimPaymentStatusArgs): void;
};

type ModalSetBonusClaimPaymentStatusState = {
  here: boolean;
};

export class ModalSetBonusClaimPaymentStatus extends React.Component<ModalSetBonusClaimPaymentStatusProps, ModalSetBonusClaimPaymentStatusState> {
  state: ModalSetBonusClaimPaymentStatusState = {
    here: true
  };

  refComment: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ModalSetBonusClaimPaymentStatusProps) {
    super(props);

    this.refComment = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalSetBonusClaimPaymentStatusProps, prevState: ModalSetBonusClaimPaymentStatusState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const comment: string = this.refComment.current ? this.refComment.current.value : "";

    const args: ModalSetBonusClaimPaymentStatusArgs = {
      comment: comment
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  sumBonusesByUid = (): SummedBonuses => {
    const summedBonuses: SummedBonuses = this.props.selectedPayments.reduce((acc: SummedBonuses, payment: BonusClaimPaymentDto) => {
      const uidKey: string = payment.uid.toString();
      if (acc[uidKey]) {
        acc[uidKey] += payment.bonus;
      } else {
        acc[uidKey] = payment.bonus;
      }
      return acc;
    }, {});
    return summedBonuses;
  };
  // #endregion

  render() {
    const summedBonuses: SummedBonuses = this.sumBonusesByUid();
    const displayBonuses: string = Object.entries(summedBonuses)
      .map(([uid, bonus]) => `${uid}:${bonus}`)
      .join(",");

    return (
      <React.Fragment>
        <BaseModal
          size="lg"
          title="Reject Bonus Claim Payments"
          onCloseClick={this.onCloseClick}
          onSubmitClick={this.onSubmitClick}
          content={
            <>
              <Row className="mb-4">
                <label className="col-sm-3 col-form-label">Comment</label>
                <Col sm={9}>
                  <textarea ref={this.refComment} className="form-control" placeholder="Comment" rows={4} maxLength={1000} />
                </Col>
              </Row>

              {this.props.newStatus == BonusPaymentStatus.PROCESSED && (
                <>
                  <Row className="mb-4">
                    <label className="col-sm-3 col-form-label">Air-drop</label>
                    <Col sm={9}>
                      <textarea className="form-control" rows={4} disabled={true} value={displayBonuses} />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>UID</th>
                            <th>Bonus</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(summedBonuses).map(([uid, bonus]) => (
                            <tr key={uid}>
                              <td>{uid}</td>
                              <td>{bonus}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </>
              )}
            </>
          }
        />
      </React.Fragment>
    );
  }
}
