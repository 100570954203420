import React from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { UidInfoButtons } from "../../components/controls/UidInfoButtons";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { PlatformUserSpotBalanceDto } from "../../lib/drivers/dto/platform-data/PlatformUserSpotBalanceDto";
import withRouterHook from "../../withRouterHook";

type PlatformUserSpotBalancePageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  uid?: string;
  showName?: string;
  page: number;
  pageSize: number;
  total: number;
};

type PlatformUserSpotBalancePageState = {
  isLoading: boolean;
  list: PlatformUserSpotBalanceDto[];

  // filter
  filter: Filter;
};

class PlatformUserSpotBalancePage extends React.Component<PlatformUserSpotBalancePageProps, PlatformUserSpotBalancePageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PlatformUserSpotBalancePageState = {
    isLoading: true,
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    }
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);

    const filter: Filter = {
      uid: queryParams.get("uid") || null,
      showName: queryParams.get("coin") || null,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterPlatformUserSpotBalanceList({
      uid: filter.uid,
      showName: filter.showName,

      limit: filter.pageSize,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      await this.setState((prevState) => {
        return {
          filter: filter,
          list: response.data,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: PlatformUserSpotBalancePageProps, prevState: PlatformUserSpotBalancePageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // private async loadData(): Promise<LogResponseDto> {
  //   const { currentPage, pageSize } = this.state;
  //   const offset = (currentPage - 1) * pageSize;

  //   const result = await PortCentralServer.Repo.client.filterLogList({
  //     limit: pageSize,
  //     offset: offset
  //   });

  //   return result;
  // }
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Platform" breadcrumbItem="Spot Balances" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">UID</Label>
                                  <Input className="form-control" name="uid" defaultValue={this.state.filter.uid} />
                                </FormGroup>
                              </Col>
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Coin</Label>
                                  <Input className="form-control" name="coin" defaultValue={this.state.filter.showName} />
                                </FormGroup>
                              </Col>
                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>uid</th>
                                      <th>showName</th>
                                      <th>allBalance</th>
                                      <th>allBtcValuatin</th>
                                      <th>btcValuatin</th>
                                      <th>canShowProfit</th>
                                      <th>checked</th>
                                      <th>coinName</th>
                                      <th>coupon_balance</th>
                                      <th>depositMin</th>
                                      <th>depositOpen</th>
                                      <th>exchange_symbol</th>
                                      <th>fiatIncome</th>
                                      <th>innerWithdrawShow</th>
                                      <th>isFiat</th>
                                      <th>lock_balance</th>
                                      <th>lock_grant_divided_balance</th>
                                      <th>lock_position_balance</th>
                                      <th>lock_position_v2_amount</th>
                                      <th>mainChainType</th>
                                      <th>nc_lock_balance</th>
                                      <th>normal_balance</th>
                                      <th>otcOpen</th>
                                      <th>overcharge_balance</th>
                                      <th>present_coin_balance</th>
                                      <th>showName</th>
                                      <th>sort</th>
                                      <th>symbolType</th>
                                      <th>total_balance</th>
                                      <th>walletTransactionOpen</th>
                                      <th>withdraw_balance</th>
                                      <th>withdrawOpen</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: PlatformUserSpotBalanceDto, key: number) => (
                                      <tr key={key}>
                                        <td>
                                          <div className="avatar-sm bg-light rounded p-2">
                                            <img className="img-fluid rounded-circle" src={item.icon} alt={item.showName} />
                                          </div>
                                        </td>
                                        <td>
                                          <UidInfoButtons uid={item.uid.toString()} />
                                        </td>
                                        <td>{`${item.showName}`}</td>
                                        <td>{`${item.allBalance}`}</td>
                                        <td>{`${item.allBtcValuatin}`}</td>
                                        <td>{`${item.btcValuatin}`}</td>
                                        <td>{`${item.canShowProfit}`}</td>
                                        <td>{`${item.checked}`}</td>
                                        <td>{`${item.coinName}`}</td>
                                        <td>{`${item.coupon_balance}`}</td>
                                        <td>{`${item.depositMin}`}</td>
                                        <td>{`${item.depositOpen}`}</td>
                                        <td>{`${item.exchange_symbol}`}</td>
                                        <td>{`${item.fiatIncome}`}</td>
                                        <td>{`${item.innerWithdrawShow}`}</td>
                                        <td>{`${item.isFiat}`}</td>
                                        <td>{`${item.lock_balance}`}</td>
                                        <td>{`${item.lock_grant_divided_balance}`}</td>
                                        <td>{`${item.lock_position_balance}`}</td>
                                        <td>{`${item.lock_position_v2_amount}`}</td>
                                        <td>{`${item.mainChainType}`}</td>
                                        <td>{`${item.nc_lock_balance}`}</td>
                                        <td>{`${item.normal_balance}`}</td>
                                        <td>{`${item.otcOpen}`}</td>
                                        <td>{`${item.overcharge_balance}`}</td>
                                        <td>{`${item.present_coin_balance}`}</td>
                                        <td>{`${item.sort}`}</td>
                                        <td>{`${item.symbolType}`}</td>
                                        <td>{`${item.total_balance}`}</td>
                                        <td>{`${item.walletTransactionOpen}`}</td>
                                        <td>{`${item.withdraw_balance}`}</td>
                                        <td>{`${item.withdrawOpen}`}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(PlatformUserSpotBalancePage);
