import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProtectedRoute } from "./components/ProtectedRoute";

// Import scss
import "./assets/scss/theme.scss";

// Pages
import { HomePage } from "./pages/HomePage";
import { NotificationsPage } from "./pages/NotificationsPage";
import { SomePage } from "./pages/SomePage";
import AcceptInvitationPage from "./pages/auth/AcceptInvitationPage";
import PasswordResetPage from "./pages/auth/PasswordResetPage";
import RequestPasswordResetPage from "./pages/auth/RequestPasswordResetPage";
import SignInPage from "./pages/auth/SignInPage";
import BonusClaimListPage from "./pages/bonus-claim/BonusClaimListPage";
import BonusClaimPaymentListPage from "./pages/bonus-claim/BonusClaimPaymentListPage";
import PlatformBalanceTotalPage from "./pages/common/PlatformBalanceTotalPage";
import ExchangePartnerDashboardPage from "./pages/exchange-partners/ExchangePartnerDashboardPage";
import ExchangePartnerIndexListPage from "./pages/exchange-partners/ExchangePartnerIndexListPage";
import ExchangePartnerListPage from "./pages/exchange-partners/ExchangePartnerListPage";
import ExchangePartnerPaymentPage from "./pages/exchange-partners/ExchangePartnerPaymentPage";
import PlatformFutureContractPage from "./pages/platform/PlatformFutureContractPage";
import PlatformFutureOrderPage from "./pages/platform/PlatformFutureOrderPage";
import PlatformFuturePositionPage from "./pages/platform/PlatformFuturePositionPage";
import PlatformSpotOrderPage from "./pages/platform/PlatformSpotOrderPage";
import PlatformSpotPairPage from "./pages/platform/PlatformSpotPairPage";
import PlatformSpotTradePage from "./pages/platform/PlatformSpotTradePage";
import PlatformUserFutureBalancePage from "./pages/platform/PlatformUserFutureBalancePage";
import PlatformUserInvitationPage from "./pages/platform/PlatformUserInvitationPage";
import PlatformUserPage from "./pages/platform/PlatformUserPage";
import PlatformUserRegistrationsByDatePage from "./pages/platform/PlatformUserRegistrationsByDatePage";
import PlatformUserSpotBalancePage from "./pages/platform/PlatformUserSpotBalancePage";
import PlatformUserTradeFollowingPage from "./pages/platform/PlatformUserTradeFollowingPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ProfileSettingsPage from "./pages/profile/ProfileSettingsPage";
import CronJobsPage from "./pages/system/CronJobsPage";
import GithubActionPage from "./pages/system/GithubActionPage";
import SystemLogPage from "./pages/system/SystemLogPage";
import TournamentEditPage from "./pages/tournament/TournamentEditPage";
import TournamentListPage from "./pages/tournament/TournamentListPage";
import TournamentOrderListPage from "./pages/tournament/TournamentOrderListPage";
import TournamentReferenceListPage from "./pages/tournament/TournamentReferenceListPage";
import TournamentReferencesByDayPage from "./pages/tournament/TournamentReferencesByDayPage";
import TournamentReportPage from "./pages/tournament/TournamentReportPage";
import UserInvitationListPage from "./pages/user/UserInvitationListPage";
import UserListPage from "./pages/user/UserListPage";

export class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          <Route path="sign-in" element={<SignInPage />} />
          <Route path="forgot-password" element={<RequestPasswordResetPage />} />
          <Route path="reset-password" element={<PasswordResetPage />} />
          <Route path="invitation" element={<AcceptInvitationPage />} />

          <Route path="/" element={ <ProtectedRoute><HomePage /></ProtectedRoute> } />

          {/* User Pages */}
          <Route path="user/list" element={ <ProtectedRoute><UserListPage /></ProtectedRoute> } />
          <Route path="user/invitations" element={ <ProtectedRoute><UserInvitationListPage /></ProtectedRoute> } />

          {/* User Pages */}

          {/* Platform Pages */}
          <Route path="platform/users" element={ <ProtectedRoute><PlatformUserPage /></ProtectedRoute> } />
          <Route path="platform/users/registrations-by-day" element={ <ProtectedRoute><PlatformUserRegistrationsByDatePage /></ProtectedRoute> } />
          <Route path="platform/users/invitations" element={ <ProtectedRoute><PlatformUserInvitationPage /></ProtectedRoute> } />
          <Route path="platform/users/trade-following" element={ <ProtectedRoute><PlatformUserTradeFollowingPage /></ProtectedRoute> } />
          <Route path="platform/users/future-balance" element={ <ProtectedRoute><PlatformUserFutureBalancePage /></ProtectedRoute> } />
          <Route path="platform/users/spot-balance" element={ <ProtectedRoute><PlatformUserSpotBalancePage /></ProtectedRoute> } />
          <Route path="platform/spot-pairs" element={ <ProtectedRoute><PlatformSpotPairPage /></ProtectedRoute> } />
          <Route path="platform/spot-orders" element={ <ProtectedRoute><PlatformSpotOrderPage  /></ProtectedRoute> } />
          <Route path="platform/spot-trades" element={ <ProtectedRoute><PlatformSpotTradePage  /></ProtectedRoute> } />
          <Route path="platform/future-contracts" element={ <ProtectedRoute><PlatformFutureContractPage /></ProtectedRoute> } />
          <Route path="platform/future-orders" element={ <ProtectedRoute><PlatformFutureOrderPage  /></ProtectedRoute> } />
          <Route path="platform/future-positions" element={ <ProtectedRoute><PlatformFuturePositionPage /></ProtectedRoute> } />
          {/* Platform Pages */}

          {/* System Pages */}
          <Route path="system/logs" element={ <ProtectedRoute><SystemLogPage /></ProtectedRoute> } />
          <Route path="system/cron-jobs" element={ <ProtectedRoute><CronJobsPage /></ProtectedRoute> } />
          <Route path="system/github-actions" element={ <ProtectedRoute><GithubActionPage /></ProtectedRoute> } />
          {/* System Pages */}

          {/* Profile Pages */}
          <Route path="profile" element={ <ProtectedRoute><ProfilePage /></ProtectedRoute> } />
          <Route path="profile/settings" element={ <ProtectedRoute><ProfileSettingsPage /></ProtectedRoute> } />
          {/* Profile Pages */}

          {/* Tournament Pages */}
          <Route path="tournaments" element={ <ProtectedRoute><TournamentListPage /></ProtectedRoute> } />
          <Route path="tournaments/:tournamentId/references-by-day" element={ <ProtectedRoute><TournamentReferencesByDayPage /></ProtectedRoute> } />
          <Route path="tournaments/:tournamentId/references" element={ <ProtectedRoute><TournamentReferenceListPage /></ProtectedRoute> } />
          <Route path="tournaments/:tournamentId/orders" element={ <ProtectedRoute><TournamentOrderListPage /></ProtectedRoute> } />
          <Route path="tournaments/:tournamentId/edit" element={ <ProtectedRoute><TournamentEditPage /></ProtectedRoute> } />
          <Route path="tournaments/reporting" element={ <ProtectedRoute><TournamentReportPage /></ProtectedRoute> } />
          {/* Tournament Pages */}

          {/* Bonus Claims */}
          <Route path="bonus-claims" element={ <ProtectedRoute><BonusClaimListPage /></ProtectedRoute> } />
          <Route path="bonus-claims/payments" element={ <ProtectedRoute><BonusClaimPaymentListPage /></ProtectedRoute> } />
          {/* Bonus Claims */}

          {/* Common Secured Pages */}
          <Route path="notifications" element={ <ProtectedRoute><NotificationsPage /></ProtectedRoute> } />
          {/* Common Secured Pages */}

          {/* Cash Back Pages */}
          {/* <Route path="cash-back/options" element={ <ProtectedRoute><CashBackOptionPage /></ProtectedRoute> } />
          <Route path="cash-back/users" element={ <ProtectedRoute><CashBackUserPage /></ProtectedRoute> } />
          <Route path="cash-back/payments" element={ <ProtectedRoute><CashBackPaymentPage /></ProtectedRoute> } />
          <Route path="cash-back" element={ <ProtectedRoute><CashBackDashboardPage /></ProtectedRoute> } /> */}
          {/* Cash Back Pages */}

          {/* Exchange Partners Pages */}
          <Route path="exchange-partners/list" element={ <ProtectedRoute><ExchangePartnerListPage /></ProtectedRoute> } />
          <Route path="exchange-partners/:exchangePartnerId/index" element={ <ProtectedRoute><ExchangePartnerIndexListPage /></ProtectedRoute> } />
          <Route path="exchange-partners" element={ <ProtectedRoute><ExchangePartnerDashboardPage /></ProtectedRoute> } />
          <Route path="exchange-partners/payments" element={ <ProtectedRoute><ExchangePartnerPaymentPage /></ProtectedRoute> } />
          {/* Exchange Partners Pages */}

          {/* Finance Pages */}
          <Route path="common/platform-balances" element={ <ProtectedRoute><PlatformBalanceTotalPage /></ProtectedRoute> } />
          {/* Finance Pages */}

          <Route path="some" element={ <ProtectedRoute><SomePage /></ProtectedRoute> } />
          
        </Routes>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
