import React from "react";
import { Col, Row } from "reactstrap";
import { ExchangePartnerIndexDto } from "../lib/drivers/dto/exchange-partners/ExchangePartnerIndexDto";
import { EnumBadgeControl } from "./controls/EnumBadgeControl";

export type TournamentDetailsComponentProps = {
  entity: ExchangePartnerIndexDto;
};

type TournamentDetailsComponentState = {
  here: boolean;
};

export class ExchangePartnerIndexDetailsComponent extends React.Component<TournamentDetailsComponentProps, TournamentDetailsComponentState> {
  state: TournamentDetailsComponentState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: TournamentDetailsComponentProps, prevState: TournamentDetailsComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <Row className="mt-4">
              <Col>
                <h5>referralCount</h5>
                <p>{Number(this.props.entity.referralCount).toLocaleString()}</p>
              </Col>
              <Col>
                <h5>totalTrades</h5>
                <p>{Number(this.props.entity.totalTrades).toLocaleString()}</p>
              </Col>
              <Col>
                <h5>totalVolume</h5>
                <p className="text-warning">
                  <strong>{Number(this.props.entity.totalVolume).toLocaleString()}</strong>
                </p>
              </Col>
              <Col>
                <h5>totalVolumeABook</h5>
                <p>{Number(this.props.entity.totalVolumeABook).toLocaleString()}</p>
              </Col>
              <Col>
                <h5>totalVolumeBBook</h5>
                <p>{Number(this.props.entity.totalVolumeBBook).toLocaleString()}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>status</h5>
                <p>
                  <EnumBadgeControl enumType="ExchangePartnerIndexStatus" value={this.props.entity.status} />
                </p>
              </Col>
              <Col>
                <h5>realizedPnlBBook</h5>
                <p>{Number(this.props.entity.realizedPnlBBook).toLocaleString()}</p>
              </Col>
              <Col>
                <h5>partnerProfit</h5>
                <p className="text-danger">
                  <strong>{Number(this.props.entity.partnerProfit).toLocaleString()}</strong>
                </p>
              </Col>
              <Col>
                <h5>averagePositionSize</h5>
                <p>{Number(this.props.entity.averagePositionSize).toLocaleString()}</p>
              </Col>
              <Col>
                <h5>averageVolume</h5>
                <p>{Number(this.props.entity.averageVolume).toLocaleString()}</p>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
