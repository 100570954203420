import React from "react";
import axios from "axios";
import moment from "moment";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { Col, Row } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";

interface WorkflowRun {
  id: number;
  name: string;
  status: string;
  conclusion: string | null;
  created_at: string;
  html_url: string;
}

interface Repo {
  name: string;
  latestRun: WorkflowRun | null;
}

export type GithubActionProps = {};

type GithubActionState = {
  repos: Repo[];
  loading: boolean;
  error: string | null;
};

class GithubAction extends React.Component<GithubActionProps, GithubActionState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: GithubActionState = {
    repos: [],
    loading: true,
    error: null
  };

  componentDidMount = async () => {
    await this.fetchWorkflowRuns();
  };

  async fetchWorkflowRuns() {
    try {
      const token = process.env.REACT_APP_GITHUB_TOKEN;
      const orgName = "bonexx";

      const reposResponse = await axios.get(`https://api.github.com/orgs/${orgName}/repos`, {
        headers: {
          Authorization: `token ${token}`
        }
      });

      const repos = reposResponse.data;

      const repoPromises = repos.map(async (repo: any) => {
        const runsResponse = await axios.get(`https://api.github.com/repos/${orgName}/${repo.name}/actions/runs`, {
          headers: {
            Authorization: `token ${token}`
          }
        });

        const runs = runsResponse.data.workflow_runs;

        // Filter workflows with names containing "Deploy" or "deploy"
        const filteredRuns = runs.filter((run: WorkflowRun) => /deploy/i.test(run.name));

        return {
          name: repo.name,
          latestRun: filteredRuns.length > 0 ? filteredRuns[0] : null
        };
      });

      let reposWithRuns = await Promise.all(repoPromises);

      reposWithRuns = reposWithRuns.sort((a, b) => {
        if (a.latestRun && b.latestRun) {
          return new Date(b.latestRun.created_at).getTime() - new Date(a.latestRun.created_at).getTime();
        } else if (a.latestRun) {
          return -1;
        } else if (b.latestRun) {
          return 1;
        } else {
          return 0;
        }
      });

      await this.setState({ repos: reposWithRuns, loading: false });
    } catch (error) {
      await this.setState({
        error: error instanceof Error ? error.message : "An error occurred",
        loading: false
      });
    }
  }

  // Function to get Bootstrap badge class based on the status
  getStatusBadgeClass(status: string, conclusion: string | null) {
    if (status === "completed") {
      if (conclusion === "success") {
        return "badge bg-success"; // Green
      } else if (conclusion === "failure" || conclusion === "cancelled") {
        return "badge bg-danger"; // Red
      } else if (conclusion === "neutral") {
        return "badge bg-secondary"; // Gray
      }
    } else if (status === "in_progress") {
      return "badge bg-primary"; // Blue
    } else if (status === "queued") {
      return "badge bg-warning text-dark"; // Orange
    }
    return "badge bg-dark"; // Default color (black)
  }

  // Function to format date using moment
  formatDate(date: string) {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  render() {
    const { repos, loading, error } = this.state;

    // if (loading) {
    //   return <div>Loading...</div>;
    // }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Github Actions" breadcrumbItem="System" />
              <Row>
                <Col xl={12}>
                  {this.state.loading ? (
                    <LoaderComponent />
                  ) : (
                    <div className="row">
                      {repos
                        .filter((ele) => ele.latestRun)
                        .map((repo) => (
                          <div className="col-md-4" key={repo.name}>
                            <div className="card mb-4">
                              <div className="card-body">
                                <h5 className="card-title">{repo.name}</h5>
                                <p className="card-text">
                                  <strong>Workflow:</strong> {repo.latestRun!.name}
                                </p>
                                <p className="card-text">
                                  Status: <span className={this.getStatusBadgeClass(repo.latestRun!.status, repo.latestRun!.conclusion)}>{repo.latestRun!.status}</span>
                                </p>
                                <p className="card-text">
                                  Conclusion:{" "}
                                  <span className={this.getStatusBadgeClass(repo.latestRun!.status, repo.latestRun!.conclusion)}>
                                    {repo.latestRun!.conclusion || "In Progress"}
                                  </span>
                                </p>
                                <p className="card-text">Created At: {this.formatDate(repo.latestRun!.created_at)}</p>
                                <a href={repo.latestRun!.html_url} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                                  View Workflow
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(GithubAction);
