import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import PagerComponent from "../../components/PagerComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { ExchangePartnerIndexDto } from "../../lib/drivers/dto/exchange-partners/ExchangePartnerIndexDto";
import withRouterHook from "../../withRouterHook";
import { ExchangePartnerIndexStatus } from "../../lib/drivers/dto/enums/ExchangePartnerIndexStatus";
import { EnumBadgeControl } from "../../components/controls/EnumBadgeControl";
import { ModalSetExchangePartnersClaimPaymentStatus, ModalSetExchangePartnersClaimPaymentStatusArgs } from "./ModalSetExchangePartnersClaimPaymentStatus";
import { ModalSetBonusClaimPaymentStatusArgs } from "../bonus-claim/ModalSetBonusClaimPaymentStatus";
import { SetBonusClaimPaymentStatusRequestDto } from "../../lib/drivers/dto/bonus/SetBonusClaimPaymentStatusRequestDto";
import { type } from "@testing-library/user-event/dist/type";

type ExchangePartnerPaymentPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = {
  exchangePartnerId?: string;
  status?: ExchangePartnerIndexStatus;
  statuses?: ExchangePartnerIndexStatus[];
  year?: number;
  month?: number;

  page: number;
  pageSize: number;
  total: number;
};

type ExchangePartnerPaymentPageState = {
  isLoading: boolean;
  startDate: Date;
  columns: any[];
  list: ExchangePartnerIndexDto[];

  // filter
  filter: Filter;

  //modals
  openModalSetExchangePartnersClaimPaymentStatus: boolean;

  exchangePartnerIndexId: string;
  status: ExchangePartnerIndexStatus;
  confirmedPartnerProfit: number;
};

class ExchangePartnerPaymentPage extends React.Component<ExchangePartnerPaymentPageProps, ExchangePartnerPaymentPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: ExchangePartnerPaymentPageState = {
    isLoading: true,
    startDate: new Date(),
    columns: [],
    list: [],

    // filter
    filter: {
      page: 1,
      pageSize: 128,
      total: 0
    },

    //modals:
    openModalSetExchangePartnersClaimPaymentStatus: false,

    exchangePartnerIndexId: null,
    status: null,
    confirmedPartnerProfit: null
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);
    console.log("queryParams", queryParams.get("year"));

    const dateFromQuery = queryParams.get("date");
    let year = null;
    let month = null;

    if (dateFromQuery) {
      // Assuming dateFromQuery is in "YYYY-MM" format
      const [yearStr, monthStr] = dateFromQuery.split("-");
      year = parseInt(yearStr, 10);
      month = parseInt(monthStr, 10);
    }

    const filter: Filter = {
      exchangePartnerId: queryParams.get("partner") || null,
      status: (queryParams.get("status") as ExchangePartnerIndexStatus) || null,
      statuses: null,
      year: year || null,
      month: month || null,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterExchangePartnerIndexList({
      exchangePartnerId: filter.exchangePartnerId,
      status: filter.status,
      statuses: filter.statuses,
      year: filter.year,
      month: filter.month,
      offset: offset
    });

    if (response) {
      filter.total = response.total;

      await this.setState((prevState) => {
        return {
          filter: filter,
          list: response.data,
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: ExchangePartnerPaymentPageProps, prevState: ExchangePartnerPaymentPageState) => {
    // execute
    if (prevState.exchangePartnerIndexId !== this.state.exchangePartnerIndexId) {
      if (typeof prevState.exchangePartnerIndexId === "string") {
        const changedList = this.state.list.filter((listEl) => listEl.exchangePartnerIndexId !== prevState.exchangePartnerIndexId);
        this.setState({ list: changedList });
      }
    }
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onActionSetExchangePartnerIndexStatusClick = async (e: React.FormEvent<HTMLButtonElement>, item: ExchangePartnerIndexDto, newStatus: ExchangePartnerIndexStatus) => {
    e.preventDefault();

    // ...
    console.log(item);

    this.setState({
      ...this.state,
      openModalSetExchangePartnersClaimPaymentStatus: true,
      exchangePartnerIndexId: item.exchangePartnerIndexId,
      status: newStatus,
      confirmedPartnerProfit: item.confirmedPartnerProfit
      // newBonusClaimPaymentStatus: newStatus
    });
  };

  onModalSetExchangePartnersClaimPaymentStatusCloseClick = async () => {
    // await this.setState((prevState) => {
    //   return { openModalSetBonusClaimPaymentStatus: false };
    // });
    this.setState({
      ...this.state,
      openModalSetExchangePartnersClaimPaymentStatus: false
    });
  };

  onModalSetBonusClaimStatusSubmitClick = async (args: ModalSetExchangePartnersClaimPaymentStatusArgs) => {
    let success: boolean = true;

    if (success) {
      const updatedBonusClaim: ExchangePartnerIndexDto = await PortCentralServer.Repo.client.setExchangePartnerIndexPaymentStatus({
        // bonusClaimId: args.claim.bonusClaimId,
        // status: args.claim.status,
        // comment: args.claim.comment
        exchangePartnerIndexId: args.exchangePartnerIndexId,
        status: args.status,
        confirmedPartnerProfit: Number(args.confirmedPartnerProfit),
        message: args.comment
      });

      this.setState({
        ...this.state,
        openModalSetExchangePartnersClaimPaymentStatus: false,
        exchangePartnerIndexId: null,
        status: null,
        confirmedPartnerProfit: null
      });

      if (!updatedBonusClaim) {
        return;
      }
      this.setState((prevState) => {
        return {
          openModalSetExchangePartnersClaimPaymentStatus: false,
          list: prevState.list.map((ele) => {
            if (ele.exchangePartnerIndexId === updatedBonusClaim.exchangePartnerIndexId) {
              ele = { ...updatedBonusClaim };
            }

            return ele;
          })
        };
      });
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  // #region Renders
  handlePageSizeChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = parseInt(e.target.value, 10);
    await this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          pageSize: newPageSize,
          page: 1 // Reset to page 1 when page size changes
        }
      }),
      () => {
        // Assuming you want to reload data and update URL here
        // this.props.history.push(`?page=1&pageSize=${newPageSize}`);
        // this.loadData();
      }
    );
  };
  // #endregion

  // getInitialDate() {
  //   const initialYear = this.state.filter.year;
  //   const inititalMonth = this.state.filter.month;

  //   // console.log("inYear and inMonth", initialYear, inititalMonth)

  //   if (initialYear && inititalMonth) {
  //     return `${initialYear}-${String(inititalMonth).padStart(2, "0")}`;
  //   }
  //   const date = new Date();

  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are 0-indexed
  //   // const day = String(date.getDate()).padStart(2, "0");

  //   return `${year}-${month}`;
  // }

  render() {
    const { year, month } = this.state.filter;
    const formattedDate = year && month ? `${year}-${String(month).padStart(2, '0')}` : ''; // Format to "YYYY-MM"

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Exchange Partners" breadcrumbItem="Payments" />
              {/* <Row className="mb-3">
                <Col>
                  <a className="btn btn-sm btn-outline-primary mr-2" href={`?year=${this.state.currentYear - 1}`}>
                    Prev Year
                  </a>
                  <span className="mx-2 h4">{this.state.currentYear}</span>
                  <a className="btn btn-sm btn-outline-primary ml-2" href={`?year=${this.state.currentYear + 1}`}>
                    Next Year
                  </a>
                  {this.state.currentYear !== currentYear && (
                    <a className="btn btn-sm btn-outline-info mx-2" href={`?year=${currentYear}`}>
                      Go to Current Year
                    </a>
                  )}
                </Col>
              </Row> */}

              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">partner</Label>
                                  <Input className="form-control" name="partner" defaultValue={this.state.filter.exchangePartnerId} />
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Status</Label>
                                  <select className="form-control select2-search-disable" name="status" defaultValue={this.state.filter.status}>
                                    <option value="">All</option>
                                    {Object.keys(ExchangePartnerIndexStatus).map((key) => (
                                      <option value={ExchangePartnerIndexStatus[key as keyof typeof ExchangePartnerIndexStatus]} key={key}>
                                        {ExchangePartnerIndexStatus[key as keyof typeof ExchangePartnerIndexStatus]}
                                      </option>
                                    ))}
                                  </select>
                                </FormGroup>
                              </Col>

                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Date</Label>
                                  {/* <div className="mb-3 row">
                                    <label htmlFor="example-date-input" className="col-md-2 col-form-label">
                                      Date
                                    </label> */}
                                  <div className="col-md-10">
                                    <input name="date" className="form-control" type="month" id="example-datetime-local-input" defaultValue={formattedDate} />
                                  </div>
                                  {/* </div> */}
                                </FormGroup>
                              </Col>

                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form>

                          <Row>
                            <Col xl={12}>
                              <div className="table-responsive">
                                <Table className="table table-bordered m-0">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Actions</th>
                                      <th>year</th>
                                      <th>month</th>
                                      <th>status</th>
                                      <th>totalVolume</th>
                                      <th>partnerProfit</th>
                                      <th>confirmedPartnerProfit</th>
                                      <th>totalTrades</th>
                                      <th>totalVolumeABook</th>
                                      <th>totalVolumeBBook</th>
                                      <th>realizedPnlABook</th>
                                      <th>realizedPnlBBook</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(this.state.list || []).map((item: ExchangePartnerIndexDto, key: number) => (
                                      <tr key={key}>
                                        <td className="nowrap actions">
                                          <Link className="btn btn-sm btn-primary" to={`/exchange-partners/${item.exchangePartnerId}/index?year=${item.year}&month=${item.month}`}>
                                            Index
                                          </Link>
                                        </td>
                                        <td className="nowrap actions">
                                          {item.status == ExchangePartnerIndexStatus.PROCESSED && (
                                            <>
                                              <button
                                                className="btn btn-sm btn-info"
                                                onClick={(e) => this.onActionSetExchangePartnerIndexStatusClick(e, item, ExchangePartnerIndexStatus.CONFIRMED)}
                                              >
                                                Confirm
                                              </button>
                                            </>
                                          )}

                                          {(item.status == ExchangePartnerIndexStatus.PROCESSED || item.status == ExchangePartnerIndexStatus.CONFIRMED) && (
                                            <>
                                              <button
                                                className="btn btn-sm btn-warning"
                                                onClick={(e) => this.onActionSetExchangePartnerIndexStatusClick(e, item, ExchangePartnerIndexStatus.PAID)}
                                              >
                                                Approve
                                              </button>
                                              <button
                                                className="btn btn-sm btn-danger"
                                                onClick={(e) => this.onActionSetExchangePartnerIndexStatusClick(e, item, ExchangePartnerIndexStatus.BLOCKED)}
                                              >
                                                Reject
                                              </button>
                                            </>
                                          )}
                                        </td>

                                        <td>{`${item.year}`}</td>
                                        <td>{`${item.month}`}</td>
                                        <td>
                                          <EnumBadgeControl enumType="ExchangePartnerIndexStatus" value={item.status} />
                                        </td>
                                        <td className="text-warning">{`${Number(item.totalVolume).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                        <td className="text-danger">{`${Number(item.partnerProfit).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                        <td className="text-danger">{`${Number(item.confirmedPartnerProfit).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                        <td>{`${item.totalTrades.toLocaleString()}`}</td>
                                        <td>{`${Number(item.totalVolumeABook).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                        <td>{`${Number(item.totalVolumeBBook).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                        <td>{`${Number(item.realizedPnlABook).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                        <td>{`${Number(item.realizedPnlBBook).toLocaleString("en-US", { maximumFractionDigits: 8 })}`}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <FormGroup>
                                  <Label for="pageSizeSelect">Page Size</Label>
                                  <select name="pageSize" id="pageSizeSelect" onChange={this.handlePageSizeChange} value={this.state.filter.pageSize}>
                                    <option value="32">32</option>
                                    <option value="64">64</option>
                                    <option value="128">128</option>
                                  </select>
                                </FormGroup>
                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
        {this.state.openModalSetExchangePartnersClaimPaymentStatus && (
          <ModalSetExchangePartnersClaimPaymentStatus
            onCloseClick={this.onModalSetExchangePartnersClaimPaymentStatusCloseClick}
            onSubmitClick={this.onModalSetBonusClaimStatusSubmitClick}
            exchangePartnerIndexId={this.state.exchangePartnerIndexId}
            newStatus={this.state.status}
            confirmedPartnerProfit={this.state.confirmedPartnerProfit}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouterHook(ExchangePartnerPaymentPage);
