import React, { Component, ChangeEvent } from "react";

interface EnumSelectControlProps {
  enumData: { [key: string]: string }; // Define the enum type more specifically if needed
  name: string;
  className?: string;
  selectedValue: string;
  includeAll?: boolean;
}

export class EnumSelectControl extends Component<EnumSelectControlProps> {
  static defaultProps = {
    includeAll: true
  };

  render() {
    const { enumData, name, selectedValue, includeAll, className } = this.props;
    const options = Object.keys(enumData).map((key) => (
      <option key={key} value={enumData[key]}>
        {enumData[key]}
      </option>
    ));

    return (
      <select className={className} name={name} defaultValue={selectedValue ?? ""}>
        {includeAll && <option value="">All</option>}
        {options}
      </select>
    );
  }
}
