import React from "react";
import { Col, Row } from "reactstrap";
import { BaseModal } from "../../components/modals/BaseModal";
import { BonusClaimPaymentDto } from "../../lib/drivers/dto/bonus/BonusClaimPaymentDto";
import { BonusPaymentStatus } from "../../lib/drivers/dto/enums/BonusPaymentStatus";
import { ExchangePartnerIndexStatus } from "../../lib/drivers/dto/enums/ExchangePartnerIndexStatus";
import { ExchangePartnerIndexPaymentStatusRequestDto } from "../../lib/drivers/dto/exchange-partners/ExchangePartnerIndexPaymentStatusRequestDto";

interface SummedBonuses {
  [uid: string]: number;
}

export type ModalSetExchangePartnersClaimPaymentStatusArgs = {
  // claim: ExchangePartnerIndexPaymentStatusRequestDto
  exchangePartnerIndexId: string;
  confirmedPartnerProfit: number;
  status: ExchangePartnerIndexStatus;
  comment: string;
};

export type ModalSetExchangePartnersClaimPaymentStatusProps = {
  // selectedPayments: BonusClaimPaymentDto[];
  exchangePartnerIndexId: string;
  confirmedPartnerProfit: number;
  newStatus: ExchangePartnerIndexStatus;
  onCloseClick(): void;
  onSubmitClick(args: ModalSetExchangePartnersClaimPaymentStatusArgs): void;
};

type ModalSetExchangePartnersClaimPaymentStatusState = {
  here: boolean;
};

export class ModalSetExchangePartnersClaimPaymentStatus extends React.Component<ModalSetExchangePartnersClaimPaymentStatusProps, ModalSetExchangePartnersClaimPaymentStatusState> {
  state: ModalSetExchangePartnersClaimPaymentStatusState = {
    here: true
  };

  refComment: React.RefObject<HTMLTextAreaElement>;
  refPartnerProfit: React.RefObject<HTMLInputElement>;

  constructor(props: ModalSetExchangePartnersClaimPaymentStatusProps) {
    super(props);

    this.refComment = React.createRef();
    this.refPartnerProfit = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: ModalSetExchangePartnersClaimPaymentStatusProps, prevState: ModalSetExchangePartnersClaimPaymentStatusState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = () => {
    const comment: string = this.refComment.current ? this.refComment.current.value : "";
    const changedPartnerProfit: string = this.refPartnerProfit.current ? this.refPartnerProfit.current.value : "";
    const args: ModalSetExchangePartnersClaimPaymentStatusArgs = {
      exchangePartnerIndexId: this.props.exchangePartnerIndexId,
      status: this.props.newStatus,
      confirmedPartnerProfit: Number(changedPartnerProfit) !== this.props.confirmedPartnerProfit ? Number(changedPartnerProfit) : Number(this.props.confirmedPartnerProfit),
      comment: comment
    };

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(args);
    }
  };
  // #endregion

  // #region State Setters
  // #endregion



  render() {
    let headingType = ""
    if (this.props.newStatus === "CONFIRMED") {
      headingType = "Confirm"
    } else if (this.props.newStatus === "BLOCKED") {
      headingType = "Reject"
    } else if(this.props.newStatus === "PAID") {
      headingType = "Approve"
    }

    return (
      <React.Fragment>
        <BaseModal
          size="lg"
          title={`${headingType} Exchange Partner Payments`}
          onCloseClick={this.onCloseClick}
          onSubmitClick={this.onSubmitClick}
          content={
            <>
              <Row className="mb-4">
                <label className="col-sm-3 col-form-label">Comment</label>
                <Col sm={9}>
                  <textarea ref={this.refComment} className="form-control" placeholder="Comment" rows={4} maxLength={1000} />
                </Col>
              </Row>

              {this.props.newStatus !== ExchangePartnerIndexStatus.BLOCKED && (
                <Row className="mb-4">
                  <label className="col-sm-3 col-form-label">Confirmed Partner Profit</label>
                  <Col sm={9}>
                    <input type="number" ref={this.refPartnerProfit} defaultValue={this.props.confirmedPartnerProfit} className="form-control" />
                  </Col>
                </Row>
              )}

              <Row className="mb-4">
                <h5>Status: {this.props.newStatus}</h5>
              </Row>
            </>
          }
        />
      </React.Fragment>
    );
  }
}
